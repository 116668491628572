import dayjs from "dayjs";
import { saveAs } from "file-saver";
import { Base64 } from "js-base64";
import _ from "lodash";
import moment from "moment";
import { toast } from "react-toastify";
import api from "../../controller/api";
import {
  consulting,
  createInfoCustomer,
  createOrder,
  customerViews,
  getAgeList,
  getDetailByPackagesUid,
  getDistricts,
  getFeeFromProvider,
  getListVoucher,
  getOTP,
  getPackageDetail,
  getPackageFee,
  getPackageList,
  getProviderList,
  getProvinces,
  uploadImage,
  verifyOTP,
} from "../../controller/bhskApi";
import { relationship, renderCategoryName } from "../extra/hard-data";
import { getParamUrl, isEmailValid } from "../extra/utils";

const ageToDays = (age) => {
  return age?.type === "day" ? age?.value : age?.value * 365;
};

const renderBenefitsDetail = (data) => {
  const benefitPrimary = (data?.primary || []).map((i) => ({
    uid: i.uid,
    value: i?.totalFeesBenefits,
    totalBenefitsFrom:
      i?.edit_total ||
      (i?.categoryFees === "phan_tram" ? i?.totalBenefitsFrom : undefined),
  }));
  const benefitAdditional = (data?.additional || [])
    .filter((a) => a.checked === true)
    .map((i) => ({
      uid: i.uid,
      value: i?.totalFeesBenefits,
      totalBenefitsFrom:
        i?.edit_total ||
        (i?.categoryFees === "phan_tram" ? i?.totalBenefitsFrom : undefined),
    }));
  return [...benefitPrimary, ...benefitAdditional];
};

export default function bhSKhoeReducer({
  state,
  dispatch,
  action,
  navigate,
  location,
  productSelected,
}) {
  const cases = {
    initial: async () => {
      const { dataSearch } = state;
      const res = {};

      const [ageRes, providerRes, provinceRes] = await Promise.all([
        getAgeList(),
        getProviderList(),
        getProvinces(),
      ]);

      if (ageRes?.statusCode !== 200) {
        toast.error(ageRes?.message || "Có lỗi xảy ra");
      }
      if (providerRes?.statusCode !== 200) {
        toast.error(providerRes?.message || "Có lỗi xảy ra");
      }

      if (provinceRes?.statusCode !== 200) {
        toast.error(provinceRes?.message || "Có lỗi xảy ra");
      }

      res.ageArr = ageRes?.result || [];
      res.providers = [
        { id: "all", title: "Tất cả" },
        ...(providerRes?.result || []),
      ];
      res.provinces = provinceRes?.result || [];
      res.dataBooking = {};
      res.dataReferal = {};

      if (!_.isEmpty(dataSearch)) {
        dispatch("getList");
      }
      return res;
    },

    getList: async () => {
      const { dataSearch, page } = state;
      const res = {};
      const ageParams = !_.isEmpty(dataSearch?.age)
        ? {
            age: {
              value: dataSearch?.age?.value,
              type: dataSearch?.age?.type,
            },
          }
        : {};

      if (_.isEmpty(ageParams)) {
        return { errors: ["ageFilter"] };
      }

      dispatch("onLoading");
      const productParams = {
        productId: 8, //suc khoe
        category: dataSearch?.category,
        providerId:
          dataSearch?.provider?.id === "all"
            ? null
            : dataSearch?.provider?.id || null,
        fees: dataSearch?.fees || 1,
        ...ageParams,
        orderBy: dataSearch?.orderBy || 1,
        gender: dataSearch?.gender !== 3 ? dataSearch?.gender : undefined,
        discount: dataSearch?.discount || 0,
        limit: 8,
        offset: 0,
      };
      const resApi = await getPackageList(productParams);
      if (resApi?.statusCode !== 200) {
        toast.error("Lỗi lấy danh sách sản phẩm");
      }
      dataSearch.hasAge = true;
      page.pageNumber = 1;
      page.total = resApi?.result?.total || 0;
      res.data = resApi?.result?.data || [];
      res.loading = false;
      res.errors = [];
      return { ...res, dataSearch, page };
    },

    changePage: async () => {
      const { dataSearch, data, page } = state;
      const res = {};
      dispatch("expanding");
      const ageParams = !_.isEmpty(dataSearch?.age)
        ? {
            age: {
              value: dataSearch?.age?.value,
              type: dataSearch?.age?.type,
            },
          }
        : {};

      const productParams = {
        productId: 8,
        category: dataSearch?.category,
        providerId:
          dataSearch?.provider?.id === "all"
            ? null
            : dataSearch?.provider?.id || null,
        fees: dataSearch?.fees || 1,
        ...ageParams,
        orderBy: dataSearch?.orderBy || 1,
        gender: dataSearch?.gender !== 3 ? dataSearch?.gender : undefined,
        discount: dataSearch?.discount || 0,
        limit: page.pageSize || 8,
        offset: page.pageSize * page.pageNumber,
      };
      const resApi = await getPackageList(productParams);
      if (resApi?.statusCode !== 200) {
        toast.error("Lỗi lấy danh sách sản phẩm");
      }
      res.data = [...data, ...resApi?.result?.data];
      page.pageNumber = page.pageNumber + 1;
      page.total = resApi?.result?.total || 0;

      res.expanding = false;
      return { ...res, page };
    },

    resetFilter: () => {
      const { dataSearch } = state;
      dataSearch.category = [];
      dataSearch.provider = null;
      dataSearch.fees = "";
      dataSearch.age = null;
      return { dataSearch };
    },

    getDetail: async () => {
      dispatch("onLoadDetail");
      const { dataStep2, dataSearch } = state;
      const { benefitsDetailUidList, infoFilter, gender } = action;

      const resApi = await getPackageDetail(
        benefitsDetailUidList,
        infoFilter,
        gender
      );
      if (resApi?.statusCode !== 200) {
        toast.error(resApi?.message || "Có lỗi xảy ra");
        return { loadDetail: false };
      }

      const detailData = resApi?.result || {};

      const categories = resApi?.result?.infoFilter?.category || [];
      const categoriesName = categories.reduce((item, current) => {
        const name = renderCategoryName(current);
        if (!item.includes(name)) {
          item.push(name);
        }
        return item;
      }, []);

      detailData.categoryFilter = categoriesName.join(", ");
      dataStep2.assessmentQuestion = resApi?.result?.assessment_question || [];

      const detailEdit = {};
      // fees: fee before discount
      detailEdit.totalFee = detailData?.feesPayment
        ? detailData?.feesPayment
        : detailData?.fees || 0;

      detailEdit.primary = detailData?.benefits?.primary || [];
      detailEdit.additional = (detailData?.benefits?.additional || [])?.map(
        (b) => ({ ...b, checked: true })
      );
      detailEdit.buyerDiscount = {
        originalFee: detailData?.fees,
        discountUid: detailData?.discount?.uid,
        discountAmount: detailData?.discount?.uid
          ? (detailData?.fees || 0) - (detailData?.feesPayment || 0)
          : 0,
      };
      detailEdit.canRecallFee = false; //reset to avoid recall getFee

      const res = {};
      //Set relationship
      const relationshipWithPolicyholder =
        (detailData?.extraInfo?.relationshipWithPolicyholder || []).find(
          (i) => i.status === "Active"
        )?.value || [];

      if (!_.isEmpty(relationshipWithPolicyholder)) {
        res.relationshipWithPolicyholder = relationshipWithPolicyholder.filter(
          (i) => i.checked === true
        );
      }

      //check age dependence
      res.ageDependence =
        (detailData?.extraInfo?.ageDependence || []).find(
          (i) => i.status === "Active"
        )?.value || {};

      let isWithinAgeRange = false;
      if (!_.isEmpty(res.ageDependence)) {
        const fromAgeInDays = ageToDays(res.ageDependence?.fromAge);
        const toAgeInDays = ageToDays(res.ageDependence?.toAge);
        const ageInDays = ageToDays(detailData?.age);
        isWithinAgeRange =
          fromAgeInDays <= ageInDays && ageInDays <= toAgeInDays;
      }

      //Set default value beginDate, gender, dob for OrderDialog
      const effectiveDate = (detailData?.extraInfo?.effectiveDate || []).find(
        (i) => i.status === "Active"
      )?.value;

      const begin = effectiveDate
        ? dayjs().add(effectiveDate, "day")
        : dayjs().add(detailData?.providerId === 16 ? 2 : 1, "day"); //NBH Bao Viet +2days

      res.beginDate = begin;
      res.endDate = begin.add(1, "year").subtract(1, "day").endOf("day");

      res.buyerGender = detailData?.gender;
      const defaultAge =
        dataSearch?.age?.type === "year"
          ? dayjs().subtract(dataSearch?.age?.value, "year").startOf("year")
          : dayjs().startOf("year");

      res.buyerDob = defaultAge;

      //Set default value of first insuredPerson at OrderDialog
      res.insuredPerson = [
        {
          insuredGender: detailData?.gender,
          insuredDob: defaultAge,
          relationship: isWithinAgeRange
            ? res.ageDependence?.relationshipWithPolicyholder
            : res.relationshipWithPolicyholder || relationship,
          newPackage: {
            ...detailData,
            benefits: {
              primary: detailEdit.primary,
              additional: (detailEdit?.additional || []).filter(
                (a) => a.checked === true
              ),
            },
          },
        },
      ];

      //get detail and open order dialog
      if (action.openOrder) {
        dispatch("openOrderDialog", {
          dataStep1: { ...state.dataStep1, ...res },
        });
      }

      const returnDataStep1 = action.openOrder
        ? {} //The new states above have been updated at OrderDialog
        : { dataStep1: { ...state.dataStep1, ...res } };

      return {
        ...returnDataStep1,
        dataStep2,
        detailData,
        detailEdit,
        loadDetail: false,
      };
    },

    getFee: async () => {
      const { detailEdit, detailData } = state;
      const benefitsDetail = renderBenefitsDetail(detailEdit);
      const params = {
        age: detailData?.age,
        gender: detailData?.gender,
        benefitsDetail,
      };

      const resApi = await getPackageFee(params);
      if (resApi?.statusCode !== 200) {
        toast.error(resApi?.message || "Có lỗi xảy ra");
      } else {
        detailEdit.totalFee = Math.floor(resApi?.result?.feesPayment || 0);
        detailEdit.newBenefits = resApi?.result?.benefitsDetail || [];
        return { detailEdit };
      }
    },

    checkAdditional: () => {
      const { detailEdit } = state;
      const { checked, index } = action;
      detailEdit.additional[index].checked = checked;
      return { detailEdit };
    },

    checkBenefitAdditional: () => {
      const { checked, index } = action;
      dispatch({ type: "checkAdditional", checked, index });
      dispatch("getFee");
    },

    editDetail: () => {
      const { detailEdit } = state;
      const { index, kind, value } = action;
      detailEdit[kind][index].edit_total = value;
      detailEdit[kind][index].error = false;

      const errorEdit = [];
      const objFee = detailEdit[kind][index];
      const minFeeOfType =
        objFee?.categoryFees !== "phan_tram"
          ? objFee?.totalFeesBenefits
          : objFee?.totalBenefitsFrom;

      const maxFeeOfType =
        objFee?.categoryFees !== "phan_tram"
          ? objFee?.totalFeesBenefits
          : objFee?.totalBenefitsTo;

      if (value < minFeeOfType) {
        errorEdit.push("minErr");
        detailEdit[kind][index].error = true;
      }

      if (value > maxFeeOfType) {
        errorEdit.push("maxErr");
        detailEdit[kind][index].error = true;
      }

      return { detailEdit, errorEdit };
    },

    handleCheck: () => {
      const { dataSearch } = state;
      const { e } = action;
      const category = _.cloneDeep(dataSearch?.category || []);

      if (e.target.checked) {
        category.push(e.target.name);
        dataSearch.category = _.cloneDeep(category);
      } else {
        const filterArr = category.filter((item) => item !== e.target.name);
        dataSearch.category = _.cloneDeep(filterArr);
      }
      return { dataSearch };
    },

    onChangeSearch: () => {
      const { dataSearch } = state;
      const { name, value } = action;
      if (name === "discount") {
        dataSearch[name] = value ? 1 : 0;
      } else {
        dataSearch[name] = value;
      }
      return { dataSearch, errors: [] };
    },

    onChangeStep1: () => {
      const { dataStep1, detailEdit } = state;
      const { name, value, canRecallDetail, canRecallFee } = action;
      dataStep1[name] = value;

      const checkBuyMyself = dataStep1?.insuredPerson?.[0]?.checkBuyMyself;
      if (name === "buyerName" && checkBuyMyself) {
        dataStep1.insuredPerson[0].insuredName = value;
      }
      if (name === "buyerGender" && checkBuyMyself) {
        dataStep1.insuredPerson[0].insuredGender = value;
      }
      if (name === "buyerDob" && checkBuyMyself) {
        dataStep1.insuredPerson[0].insuredDob = value;
      }
      if (name === "buyerPrivateId" && checkBuyMyself) {
        dataStep1.insuredPerson[0].insuredPrivateId = value;
      }
      if (name === "district" && checkBuyMyself) {
        dataStep1.insuredPerson[0].insuredDistrict = value;
      }
      if (name === "buyerEmail" && checkBuyMyself) {
        dataStep1.insuredPerson[0].insuredEmail = value;
      }
      if (name === "buyerPhone" && checkBuyMyself) {
        dataStep1.insuredPerson[0].insuredPhone = value;
      }

      if (name === "beginDate") {
        dataStep1.endDate = value
          .add(1, "year")
          .subtract(1, "day")
          .endOf("day");
      }

      detailEdit.canRecallDetail = canRecallDetail;
      detailEdit.canRecallFee = canRecallFee;

      return { dataStep1, detailEdit };
    },

    getBuyerDistrict: async () => {
      const { dataStep1 } = state;
      const { value } = action;
      dataStep1.province = value;
      if (dataStep1?.insuredPerson?.[0]?.checkBuyMyself) {
        dataStep1.insuredPerson[0].insuredProvince = value;
        dataStep1.insuredPerson[0].insuredDistrict = {};
      }
      if (value?.id) {
        const responseApi = await getDistricts(value?.id);
        if (responseApi?.statusCode !== 200) {
          return toast.error(responseApi?.message || "Có lỗi xảy ra");
        }
        dataStep1.district = {};
        dataStep1.districtArr = responseApi?.result || [];
      } else {
        dataStep1.district = {};
        dataStep1.districtArr = [];
      }
      return { dataStep1 };
    },

    onChangeStep2: () => {
      const { dataStep2 } = state;
      const { index, value, personIndex } = action;
      dataStep2.assessmentAnswer[personIndex].assessmentQuestion[index].answer =
        value === "true";
      return { dataStep2 };
    },

    prevStep: () => {
      const { currentStep, dataStep3 } = state;
      if (currentStep === 3) {
        dataStep3.linkSignImg = null;
      }
      return { currentStep: currentStep - 1 };
    },

    nextStep: async () => {
      const { currentStep, dataStep1, dataStep2, detailData } = state;
      if (currentStep === 1) {
        const errors = [];

        //check value insured info
        dataStep1.insuredPerson.forEach((item, index) => {
          if (item.checkBuyMyself) {
            const keys = [
              "insuredName",
              "insuredGender",
              "insuredDob",
              "insuredPrivateId",
            ];
            keys.forEach((i) => {
              if (!item[i]) {
                errors.push(`${i}${index}`);
              }
            });
          } else {
            const keys = [
              "insuredName",
              "insuredGender",
              "insuredDob",
              "insuredPrivateId",
              "relationshipWithBuyer",
            ];
            keys.forEach((i) => {
              if (!item[i]) {
                errors.push(`${i}${index}`);
              }
            });
          }

          item?.insuredEmail &&
            !isEmailValid(item?.insuredEmail || "") &&
            errors.push(`insuredEmailValid${index}`);

          item?.insuredPhone &&
            (item?.insuredPhone || "").length < 10 &&
            errors.push(`insuredPhoneValid${index}`);

          item?.insuredPrivateId &&
            ![9, 12].includes((item?.insuredPrivateId || "").length) &&
            errors.push(`insuredPrivateIdValid${index}`);

          if (item?.insuredDob) {
            const insuredDob = moment(item?.insuredDob.format("YYYY/MM/DD"));
            if (moment().diff(insuredDob, "y") > 100) {
              errors.push(`dobValid${index}`);
            }
            if (moment(insuredDob).isAfter(moment())) {
              errors.push(`dobFuture${index}`);
            }
          }
          if (item?.isWithinAgeDepends && !dataStep1?.checkBuyMyself) {
            errors.push(`isWithinAgeDepends-${index}`);
          }
        });

        //check buyer info
        dataStep1?.buyerEmail &&
          !isEmailValid(dataStep1?.buyerEmail || "") &&
          errors.push("buyerEmailValid");

        dataStep1?.buyerPhone &&
          (dataStep1?.buyerPhone || "").length < 10 &&
          errors.push("buyerPhoneValid");

        dataStep1?.buyerPrivateId &&
          ![9, 12].includes((dataStep1?.buyerPrivateId || "").length) &&
          errors.push("buyerPrivateIdValid");

        const begin = moment(dataStep1?.beginDate.format("YYYY/MM/DD"));
        if (moment().diff(begin, "d") > 0) {
          errors.push("dateValid");
        }

        const buyerDob = moment(dataStep1?.buyerDob.format("YYYY/MM/DD"));
        if (moment().diff(buyerDob, "y", true) < 18) {
          errors.push("buyerDobValid");
        }
        if (moment().diff(buyerDob, "y") > 100) {
          errors.push("buyerBirthdayValid");
        }
        if (moment(buyerDob).isAfter(moment())) {
          errors.push("buyerDobFuture");
        }

        //check age insured person within age depends
        if (errors.some((e) => e.startsWith("isWithinAge"))) {
          const foundItem = errors.find((i) => i.startsWith("isWithinAge"));
          const index = foundItem.split("-")[1];
          dataStep1.warning = {
            open: true,
            type: "isWithinAgeDepends",
            data: dataStep1.insuredPerson?.[index]?.insuredName || "",
          };
          return { errors, dataStep1 };
        }

        if (errors.length === 0) {
          //call get fee from provider
          const params = {
            providerId: detailData?.providerId,
            productId: detailData?.productId,
            pbpId: detailData?.pbpId,
            packageUid: detailData?.uid,
            beginDate: moment(dataStep1?.beginDate?.toDate()).format(
              "DD/MM/YYYY"
            ),
            endDate: moment(dataStep1?.endDate?.toDate()).format("DD/MM/YYYY"),
            oldInsCertNo: dataStep1?.oldInsCertNo,
            insuredPersons: (dataStep1?.insuredPerson || []).map((person) => ({
              fullname: person?.insuredName,
              dob: moment(person?.insuredDob?.toDate()).format("DD/MM/YYYY"),
              gender: person?.insuredGender,
              privateId: person?.insuredPrivateId,
              province: {
                id: person?.insuredProvince?.id,
                title: person?.insuredProvince?.title,
              },
              district: {
                id: person?.insuredDistrict?.id,
                title: person?.insuredDistrict?.title,
              },
              address: !person?.checkBuyMyself
                ? `${person?.insuredDistrict?.title || ""}, ${
                    person?.insuredProvince?.title || ""
                  }`
                : dataStep1?.buyerAddress,
              relationshipWithBuyer: !person?.checkBuyMyself
                ? person?.relationshipWithBuyer?.name
                : "Bản thân",
              benefitDetailUid: [
                ...(person?.newPackage?.benefits?.additional || []),
                ...(person?.newPackage?.benefits?.primary || []),
              ].map((item) => item?.uid),
            })),
          };

          const resApi = await getFeeFromProvider(params);

          if (
            resApi?.statusCode !== 200 &&
            resApi?.code === "INSURER_RENEWAL_LIST_NOT_MATCH_ERROR"
          ) {
            return toast.error(resApi?.message || "Thông tin tái tục sai");
          }

          let changeFee = false;
          const paymentFeesFromProvider =
            resApi?.result?.paymentFeesFromProvider || 0;

          //if don't have paymentFeesFromProvider -> Buy normally
          if (paymentFeesFromProvider) {
            if (resApi?.result?.spreadFees > 0) {
              changeFee = true;
              dataStep1.warning = {
                open: true,
                type: "warningFeeFromProvider",
                data: resApi?.result || {},
              };
            } else {
              //if equal price => auto set fees, dont show popup
              dispatch({
                type: "acceptChangeFee",
                dataFromProvider: resApi?.result?.data,
              });
            }
          }
          //create new assessmentAnswer and merge the old one if it already exists
          const newAssessmentAnswer = dataStep1.insuredPerson.map((person) => {
            const oldAnswerItem = (dataStep2?.assessmentAnswer || []).find(
              (i) => i?.insuredName === person.insuredName
            );
            if (oldAnswerItem) {
              return oldAnswerItem;
            }
            return {
              insuredPrivateId: person?.insuredPrivateId,
              insuredName: person?.insuredName,
              assessmentQuestion: _.cloneDeep(dataStep2?.assessmentQuestion),
            };
          });
          dataStep2.assessmentAnswer = [...newAssessmentAnswer];
          const currentStep = changeFee ? 1 : 2;
          return { errors, currentStep, dataStep1, dataStep2 };
        }
        return { errors };
      }

      if (currentStep === 2) {
        const errors = [];
        const errPerson = [];

        (dataStep2?.assessmentAnswer || []).forEach((group, groupIndex) => {
          group.assessmentQuestion.forEach((question, quesIndex) => {
            if (!question?.required && question.answer === true) {
              errors.push(`notEnoughCondition-i${groupIndex}-q${quesIndex}`);
              errPerson.push({
                insuredName: group?.insuredName,
                insuredPrivateId: group?.insuredPrivateId,
              });
            }
          });
        });
        if (errors.length === 0) {
          return { errors, currentStep: 3 };
        } else {
          const uniqueArr = errPerson.filter(
            (item, index, self) =>
              index ===
              self.findIndex(
                (t) => t.insuredPrivateId === item.insuredPrivateId
              )
          );
          dataStep2.warning =
            uniqueArr.length === dataStep2?.assessmentAnswer.length
              ? { open: true, type: "error", errPerson: uniqueArr }
              : {
                  open: true,
                  type: "warningInsuredPerson",
                  errPerson: uniqueArr,
                };
        }
        return { errors, dataStep2 };
      }
    },

    clear: () => ({ clear: true }),

    resign: () => {
      const { dataStep3 } = state;
      dataStep3.linkSignImg = "";
      return { clear: false, dataStep3 };
    },
    uploadG2: async () => {
      const { dataStep3, errors } = state;
      const { file, name, nameFile } = action;
      dispatch("loadingUploadImg");

      var form = new FormData();
      const timestamp = +new Date();
      form.append("storage", "s3");
      form.append("file", file);
      form.append("path", `/user/${nameFile}/${timestamp}-${name}`);

      const response = await uploadImage(form);

      if (!response?.complete) {
        dataStep3.linkSignImg = "";
        dataStep3.loadingImg = false;
        errors.push("linkSignImg");
        return { dataStep3, errors };
      }

      dataStep3.linkSignImg = response?.link || null;
      dataStep3.loadingImg = false;
      return { dataStep3 };
    },

    downloadFile: async () => {
      const { path, name } = action;
      toast.success("Đang tải tài liệu. Vui lòng chờ giây lát...");
      const dataApi = await api.apiGetFileByUrl(path);
      saveAs(dataApi, `${name}`);
    },

    checkAgree: () => {
      const { dataStep3 } = state;
      const { name, value } = action;
      dataStep3[name] = value;
      return { dataStep3 };
    },

    loadingUploadImg: () => {
      const { dataStep3 } = state;
      dataStep3.loadingImg = true;
      return { dataStep3 };
    },

    loadingCreatePDF: () => {
      const { detailData } = state;
      detailData.loadingPDF = action.value;
      return { detailData };
    },

    create: async () => {
      const { dataStep1, dataStep2, dataStep3, detailData } = state;
      const { verifyOTPCode } = action;
      dispatch("openNoti");

      const assessmentQuestionParams = [];
      dataStep2?.assessmentAnswer?.forEach((item) => {
        item.assessmentQuestion.forEach((question) => {
          const existingQuestion = assessmentQuestionParams.find(
            (q) => q?.id === question?.id
          );

          if (existingQuestion) {
            existingQuestion.responses.push({
              insuredPrivateId: item.insuredPrivateId,
              answer: question.answer,
            });
          } else {
            assessmentQuestionParams.push({
              id: question.id,
              content: question.content,
              required: question.required,
              responses: [
                {
                  insuredPrivateId: item.insuredPrivateId,
                  answer: question.answer,
                },
              ],
            });
          }
        });
      });

      const orderData = {
        productCode: "sk",
        productId: detailData?.productId,
        pbpId: detailData?.pbpId,
        providerId: detailData?.providerId,
        buyerInfo: {
          name: dataStep1?.buyerName,
          privateId: dataStep1?.buyerPrivateId,
          phone: dataStep1?.buyerPhone,
          email: dataStep1?.buyerEmail,
          gender: dataStep1?.buyerGender,
          address: dataStep1?.buyerAddress,
          dob: dataStep1?.buyerDob
            ? moment(dataStep1?.buyerDob?.toDate()).format("DD/MM/YYYY")
            : undefined,
        },
        insuredInfo: (dataStep1?.insuredPerson || []).map((person) => ({
          insuredName: person?.insuredName,
          insuredDob: moment(person?.insuredDob?.toDate()).format("DD/MM/YYYY"),
          insuredPrivateId: person?.insuredPrivateId,
          insuredPhone: person?.insuredPhone,
          insuredEmail: person?.insuredEmail,
          insuredAddress: !person?.checkBuyMyself
            ? `${person?.insuredDistrict?.title || ""}, ${
                person?.insuredProvince?.title || ""
              }`
            : dataStep1?.buyerAddress,
          insuredGender: person?.insuredGender,
          province: {
            id: person?.insuredProvince?.id,
            title: person?.insuredProvince?.title,
          },
          district: {
            id: person?.insuredDistrict?.id,
            title: person?.insuredDistrict?.title,
          },
          relationshipWithBuyerCode: person?.relationshipWithBuyer?.code,
          relationshipWithBuyerName: person?.relationshipWithBuyer?.name,
          fees: person?.fees,
          discountUid: person?.discountUid,
          discountAmount: person?.discountAmount,
          benefitsDetail: person?.benefitsDetail,
          feesFromGC: person?.feesFromGC,
        })),
        extraInfo: {
          otp: verifyOTPCode,
          fees: dataStep1?.totalFeeOrder || 0,
          feesPayment:
            (dataStep1?.totalFeeOrder || 0) - (dataStep3?.discountAmount || 0),
          referalCode: dataStep1?.referalCode,
          beginDate: moment(dataStep1?.beginDate?.toDate()).format(
            "DD/MM/YYYY"
          ),
          endDate: moment(dataStep1?.endDate?.toDate()).format("DD/MM/YYYY"),
          assessmentQuestion: assessmentQuestionParams,
          vatInfo:
            dataStep3?.exportVat === "2"
              ? dataStep3?.updatedVatInfo
              : undefined,
          signature: dataStep3?.linkSignImg,
          packagesUid: detailData?.uid,
          oldInsCertNo: dataStep1?.oldInsCertNo,
          voucherDiscountUid: dataStep3?.voucher?.uid,
        },
      };

      const resApi = await createOrder(orderData);
      if (resApi?.statusCode !== 200) {
        dataStep3.openNoti = false;
        toast.error(resApi?.message || "Lỗi không tạo được đơn hàng");
        return { dataStep3 };
      }
      dataStep3.openNoti = false;
      dataStep3.paymentLink = resApi?.result?.paymentLink || "";
      return { dataStep3, openSuccess: true };
    },

    selectOTPType: () => {
      const { dataOTP } = state;
      dataOTP.type = action.name;
      dataOTP.isActive = true;
      return { dataOTP };
    },

    getOTPCode: async () => {
      const { dataOTP, dataStep1, detailData } = state;
      const p =
        dataOTP.type === "sms"
          ? { phoneNumber: dataStep1?.buyerPhone }
          : { mailTo: dataStep1?.buyerEmail };
      const data = {
        type: dataOTP.type, // mail hoặc sms
        // merchantId: "",
        packagesTitle: detailData?.packagesTitle,
        productTitle: detailData?.productTitle,
        buyerName: dataStep1?.buyerName,
        ...p,
      };
      const resApi = await getOTP(data);
      if (resApi?.statusCode !== 200) {
        toast.error(resApi?.message || "Không lấy được mã OTP");
      }
    },

    verifyOTPCode: async () => {
      const { dataOTP, dataStep1 } = state;
      const { otp } = action;

      const p =
        dataOTP.type === "sms"
          ? { phoneNumber: dataStep1?.buyerPhone }
          : { mailTo: dataStep1?.buyerEmail };
      const data = {
        type: dataOTP?.type,
        // merchantId: "",
        buyerName: dataStep1?.buyerName,
        reason: "",
        otp,
        ...p,
      };
      const resApi = await verifyOTP(data);
      if (resApi?.statusCode !== 200) {
        return toast.error(resApi?.message || "Mã OTP không hợp lệ");
      }
      dispatch("closeOTPDialog");
      dispatch({ type: "create", verifyOTPCode: otp });
    },

    setSelectItem: () => ({ selectedItem: action.item }),

    openOrderDialog: () => {
      const { selectedItem, dataStep1 } = state;
      //set info customer
      const infoCustomer = {};
      const infoToViewPackagesDetail =
        JSON.parse(localStorage.getItem("infoToViewPackagesDetail")) || {};
      if (!_.isEmpty(infoToViewPackagesDetail)) {
        infoCustomer.buyerName = infoToViewPackagesDetail?.customerName;
        infoCustomer.buyerPhone = infoToViewPackagesDetail?.customerPhone;
      }

      //clone old data step1 if old uid = selected uid package
      const oldOrderData =
        JSON.parse(localStorage.getItem("oldOrderData")) || {};

      const isOldPackage =
        oldOrderData?.packageUid === selectedItem?.uid &&
        oldOrderData?.buyerGender === selectedItem?.gender;

      //clone old insuredPerson
      // let newPerson = [];
      // if (isOldPackage && !_.isEmpty(oldOrderData?.insuredPerson)) {
      //   newPerson = (oldOrderData?.insuredPerson || []).map((item) => ({
      //     ...item,
      //     insuredDob: item?.insuredDob ? dayjs(item?.insuredDob) : undefined,
      //   }));
      // }

      const newDataStep1 = isOldPackage
        ? {
            ...oldOrderData,
            beginDate: dataStep1?.beginDate,
            endDate: dataStep1?.endDate,
            buyerDob: dataStep1?.buyerDob
              ? dayjs(dataStep1?.buyerDob)
              : undefined,
            checkBuyMyself: false, //reset insured person arr
            insuredPerson: dataStep1?.insuredPerson, //add default fisrt insured person
            district: null, //reset district to recall fee
          }
        : { ...dataStep1, ...infoCustomer };

      newDataStep1.referalCode = getParamUrl("referalCode");
      newDataStep1.totalFeeOrder = (dataStep1?.insuredPerson || []).reduce(
        (total, item) => total + +(item?.feesPayment || 0),
        0
      );
      return {
        dataStep1: _.cloneDeep(newDataStep1),
        openOrder: true,
        openDetail: false,
      };
    },
    closeOrderDialog: () => {
      const res = {};
      const code = getParamUrl("code");
      navigate(`?code=${code}`);
      localStorage.setItem(
        "oldOrderData",
        JSON.stringify({
          ...state.dataStep1,
          packageUid: state.detailData?.uid,
          oldBenefitsDetail: state.detailEdit?.newBenefits,
        })
      );

      res.errors = [];
      res.currentStep = 1;
      res.dataStep1 = {};
      res.dataStep2 = {};
      res.dataStep3 = {};
      res.openOrder = false;
      return res;
    },

    openDetailDialog: () => ({ openDetail: true }),
    closeDetailDialog: () => {
      const code = getParamUrl("code");
      navigate(`?code=${code}`);
      return { openDetail: false, detailData: {}, errorEdit: [] };
    },

    openOTPDialog: () => ({ openOTPVerify: true }),
    closeOTPDialog: () => ({ openOTPVerify: false, dataOTP: {} }),

    openNoti: () => {
      const { dataStep3 } = state;
      dataStep3.openNoti = true;
      return { dataStep3, errors: [] };
    },

    onLoading: () => ({ loading: true }),
    onLoadDetail: () => ({ loadDetail: true }),
    expanding: () => ({ expanding: true }),

    onChangeBooking: () => {
      const { dataBooking } = state;
      dataBooking[action.name] = action.value;
      return { dataBooking };
    },
    sendBooking: async () => {
      const { dataBooking, detailData } = state;
      const errors = [];
      dataBooking?.phone &&
        (dataBooking?.phone || "").length < 10 &&
        errors.push("bookingPhone");

      if (errors.length > 0) {
        return { errors };
      }

      dispatch("loadBooking");
      const p = {
        product_id: productSelected.id,
        product_title: productSelected.title,
        provider_id: detailData?.providerId,
        provider_title: detailData?.providerTitle,
        pbp_id: detailData?.pbpId,
        pbp_title: detailData?.productTitle,
        package_uid: detailData?.uid,
        package_title: detailData?.packagesTitle,
        appointment_fullname: dataBooking?.name,
        appointment_phone_number: dataBooking?.phone,
        appointment_time: dataBooking?.time,
        appointment_note: dataBooking?.message,
        appointment_methods: dataBooking?.method,
      };

      const resApi = await consulting(p);
      if (resApi?.statusCode !== 200) {
        toast.error(resApi?.message || "Đặt lịch hẹn không thành công!");
      } else {
        toast.success("Đặt lịch hẹn tư vấn thành công!");
      }
      return { dataBooking: {}, errors: [] };
    },
    openBooking: () => {
      const { dataBooking } = state;
      dataBooking.open = true;
      dataBooking.time = "any";
      dataBooking.method = "zalo";

      //set info customer
      const infoToViewPackagesDetail =
        JSON.parse(localStorage.getItem("infoToViewPackagesDetail")) || {};

      if (!_.isEmpty(infoToViewPackagesDetail)) {
        dataBooking.name = infoToViewPackagesDetail?.customerName;
        dataBooking.phone = infoToViewPackagesDetail?.customerPhone;
      }
      return { dataBooking };
    },
    closeBooking: () => {
      return { dataBooking: {}, errors: [] };
    },
    loadBooking: () => {
      const { dataBooking } = state;
      dataBooking.loading = true;
      return { dataBooking };
    },

    checkDob: () => {
      const { index, age, dob } = action;
      const errors = [];
      if (age > 100) {
        errors.push(index ? `dobValid${index}` : "buyerBirthdayValid");
      }
      if (moment(dob).isAfter(moment())) {
        errors.push(index ? `dobFuture${index}` : "buyerDobFuture");
      }
      return { errors };
    },

    loadingFee: () => {
      const { detailEdit } = state;
      detailEdit.loadingFee = true;
      return { detailEdit };
    },

    recallGetDetailByUid: async () => {
      const { detailData, dataStep1, dataStep2, detailEdit } = state;
      let recall = false;
      let ageToYear = 0;
      let ageToDay = 0;

      const dob = dataStep1?.buyerDob?.toDate();
      const ageValid = moment().diff(dob, "y", true);

      if (isNaN(ageValid) || ageValid > 100) {
        return;
      }

      if (dob && dob != "Invalid Date" && ageValid) {
        ageToYear = Math.floor(moment().diff(dob, "y", true));
        dispatch({ type: "checkDob", age: ageToYear, dob });

        if (ageToYear < 1) {
          ageToDay = Math.floor(moment().diff(dob, "d", true));
        }

        if (
          ageToYear !== detailData?.age?.value &&
          (ageToYear > 0 || ageToDay > 0) &&
          ageToYear < 101
        ) {
          recall = true;
        }
      }

      if (
        dataStep1?.buyerGender
        //&& dataStep1?.insuredGender !== detailData?.gender
      ) {
        recall = true;
      }

      if (recall) {
        dispatch("loadingFee");
        const packagesUid = Base64.encode(
          JSON.stringify(detailData?.uid || "")
        );
        const infoFilter = Base64.encode(
          JSON.stringify({
            age: {
              value:
                ageToDay > 0 ? ageToDay : ageToYear || detailData?.age?.value,
              type: ageToDay > 0 ? "day" : "year",
            },
          })
        );
        const gender = Base64.encode(
          JSON.stringify(dataStep1?.buyerGender || detailData?.gender)
        );

        const resApi = await getDetailByPackagesUid(
          packagesUid,
          infoFilter,
          gender
        );

        if (
          resApi?.statusCode !== 200 ||
          _.isEmpty(resApi?.result?.benefits?.primary || [])
        ) {
          detailEdit.loadingFee = false;
          detailEdit.totalFee = 0;

          if (dataStep1.insuredPerson[0]?.checkBuyMyself) {
            dataStep1.insuredPerson[0].feesPayment = 0;
            dataStep1.totalFeeOrder = (dataStep1.insuredPerson || []).reduce(
              (total, item) => total + +(item?.feesPayment || 0),
              0
            );

            dataStep2.warning = {
              open: true,
              type:
                dataStep1.insuredPerson.length > 1 ? "warningPackage" : "error",
              errPerson: [
                {
                  insuredName: dataStep1?.buyerName,
                  insuredPrivateId: dataStep1?.buyerPrivateId,
                  index: 0,
                },
              ],
            };
          }
          return { detailEdit, dataStep1, dataStep2 };
        } else {
          //compare old - new Primary benefit
          // const newBenefitPrimary = newBenefits?.primary || [];
          // const newBenefitPrimaryUid = newBenefitPrimary.map(
          //   (b) => b?.benefitsUid
          // );

          // const missingBenefitPrimary = (detailEdit?.primary || []).filter(
          //   (item) => !newBenefitPrimaryUid.includes(item?.benefitsUid)
          // );
          //get new benefits with the same benefitsUid as the old one
          // dataStep1.commonNewBenefitPrimary = newBenefitPrimary.filter(
          //   (newItem) =>
          //     (detailEdit?.primary || []).some(
          //       (oldItem) => oldItem?.benefitsUid === newItem?.benefitsUid
          //     )
          // );

          //compare old - new Additional benefit
          // const selectedBenefitAdditional = (
          //   detailEdit?.additional || []
          // ).filter((a) => a.checked === true);

          // const newBenefitAdditional = newBenefits?.additional || [];
          // const newBenefitAdditionalUid = newBenefitAdditional.map(
          //   (b) => b?.benefitsUid
          // );

          // const missingBenefitAdditional = selectedBenefitAdditional.filter(
          //   (item) => !newBenefitAdditionalUid.includes(item?.benefitsUid)
          // );

          //get new benefits checked with the same benefitsUid as the old one
          // dataStep1.commonNewBenefitAdditional = newBenefitAdditional
          //   .filter((newItem) =>
          //     selectedBenefitAdditional.some(
          //       (oldItem) => oldItem?.benefitsUid === newItem?.benefitsUid
          //     )
          //   )
          //   .map((item) => ({ ...item, checked: true }));

          // const missingAllBenefits = [
          //   ...missingBenefitPrimary,
          //   ...missingBenefitAdditional,
          // ];
          // if (missingAllBenefits.length === 0) {
          //   //acceptRemovalOfBenefit
          //   dataStep2.warning = {};
          //   detailEdit.canRecallFee = true;
          //   detailEdit.primary = _.cloneDeep(
          //     dataStep1?.commonNewBenefitPrimary
          //   );
          //   detailEdit.additional = _.cloneDeep(
          //     dataStep1?.commonNewBenefitAdditional
          //   );
          // } else {
          //   dataStep2.warning = { open: true, type: "warningBenefit" };
          //   dataStep1.missingBenefitTitle = missingAllBenefits
          //     .map((i) => i?.benefitsTitle)
          //     .join(", ");
          // }

          dataStep2.warning = {};
          detailEdit.primary = resApi?.result?.benefits?.primary || [];
          detailEdit.additional = (
            resApi?.result?.benefits?.additional || []
          )?.map((b) => ({
            ...b,
            checked: true,
          }));
          detailEdit.age = {
            value:
              ageToDay > 0 ? ageToDay : ageToYear || detailData?.age?.value,
            type: ageToDay > 0 ? "day" : "year",
          };
          detailEdit.canRecallFee = true;
          detailEdit.loadingFee = false;
          return { detailEdit, dataStep1, dataStep2 };
        }
      }
    },

    // acceptRemovalOfBenefit: () => {
    //   const { dataStep1, detailEdit } = state;
    //   detailEdit.primary = _.cloneDeep(dataStep1?.commonNewBenefitPrimary);
    //   detailEdit.additional = _.cloneDeep(
    //     dataStep1?.commonNewBenefitAdditional
    //   );
    //   detailEdit.canRecallFee = true;
    //   dataStep1.openWarning = false;
    //   dataStep1.notiType = "";
    //   return { detailEdit, dataStep1 };
    // },

    recallGetBuyerFee: async () => {
      const { detailData, dataStep1, detailEdit } = state;
      if (dataStep1?.insuredPerson?.[0]?.checkBuyMyself) {
        dispatch({ type: "loadingFeeInsured", index: 0 });
      }

      const benefitsDetail = renderBenefitsDetail(detailEdit);
      let proDisParams = {};
      if (!_.isEmpty(dataStep1?.province) && !_.isEmpty(dataStep1?.district)) {
        proDisParams = {
          province: {
            id: dataStep1?.province?.id,
            title: dataStep1?.province?.title,
          },
          district: {
            id: dataStep1?.district?.id,
            title: dataStep1?.district?.title,
          },
        };
      }

      const params = {
        age: detailEdit?.age || detailData?.age,
        gender: dataStep1?.buyerGender || detailData?.gender,
        benefitsDetail,
        ...proDisParams,
      };

      const responseFee = await getPackageFee(params);
      if (responseFee?.statusCode !== 200) {
        toast.error(responseFee?.message || "Có lỗi xảy ra");
      } else {
        detailEdit.totalFee = Math.floor(
          responseFee?.result?.feesPayment
            ? responseFee?.result?.feesPayment
            : responseFee?.result?.fees || 0
        );
        detailEdit.newBenefits = _.cloneDeep(benefitsDetail);

        //state tmp if not checked buyMyself yet.
        detailEdit.buyerDiscount = {
          originalFee: responseFee?.result?.fees,
          discountUid: responseFee?.result?.discountUid,
          discountAmount:
            (responseFee?.result?.fees || 0) -
            (responseFee?.result?.feesPayment || 0),
        };
        //change fee for insured person = buyer
        const newInsured = dataStep1?.insuredPerson?.map((person) => {
          if (!person?.checkBuyMyself) {
            return person;
          }
          return {
            ...person,
            benefitsDetail: _.cloneDeep(benefitsDetail),
            feesPayment: responseFee?.result?.feesPayment || 0,
            feesPaymentFromGC: responseFee?.result?.feesPayment || 0, //remember origin feesPayment
            fees: responseFee?.result?.fees || 0,
            discountUid: responseFee?.result?.discountUid,
            discountAmount:
              (responseFee?.result?.fees || 0) -
              (responseFee?.result?.feesPayment || 0),
          };
        });
        dataStep1.insuredPerson = _.cloneDeep(newInsured);
        dataStep1.totalFeeOrder = newInsured.reduce(
          (total, item) => total + +(item?.feesPayment || 0),
          0
        );
      }
      dataStep1.insuredPerson[0].loadingFee = false;
      detailEdit.loadingFee = false;
      detailEdit.canRecallFee = false;
      return { detailEdit, dataStep1 };
    },

    handleDetail: () => {
      const { selectedItem } = state;
      const params = new URLSearchParams(location.search);

      selectedItem?.benefitsDetailUidList &&
        params.set(
          "benefitsDetailUidList",
          selectedItem?.benefitsDetailUidList
        );
      selectedItem?.infoFilter &&
        params.set("infoFilter", selectedItem?.infoFilter);
      selectedItem?.gender &&
        params.set("gender", Base64.encode(selectedItem?.gender || ""));

      navigate(`${location.pathname}?${params.toString()}`);
      return { dataInfo: {} };
    },

    checkInfoToViewDetail: () => {
      const { item } = action;
      dispatch({ type: "setSelectItem", item });

      const infoToViewPackagesDetail =
        JSON.parse(localStorage.getItem("infoToViewPackagesDetail")) || {};

      if (_.isEmpty(infoToViewPackagesDetail)) {
        return { dataInfo: { open: true } };
      } else {
        dispatch("handleDetail");
      }
    },
    onChangeInfo: () => {
      const { dataInfo } = state;
      const { name, value } = action;
      dataInfo[name] = value;
      return { dataInfo };
    },
    sendInfoToView: async () => {
      const { dataInfo, selectedItem } = state;
      if (dataInfo?.phone && (dataInfo?.phone || "").length < 10) {
        dataInfo.error = true;
        return { dataInfo };
      }

      const data = {
        customerName: dataInfo?.name,
        customerPhone: dataInfo?.phone,
        packageUid: selectedItem?.uid,
      };

      const resApi = await createInfoCustomer(data);
      if (resApi?.statusCode !== 200) {
        return toast.error(resApi?.message || "Lỗi tạo thông tin khách hàng");
      } else {
        localStorage.setItem(
          "infoToViewPackagesDetail",
          JSON.stringify({
            ...data,
            createDate: moment().format("DD/MM/YYYY HH:mm:ss"),
          })
        );

        dispatch("handleDetail");
      }
    },
    closeInfo: () => ({ dataInfo: {} }),

    closeWarningStep2: () => {
      const { dataStep2 } = state;
      dataStep2.warning = {};
      return { dataStep2 };
    },

    closeWarningStep1: () => {
      const { dataStep1 } = state;
      dataStep1.warning = {};
      return { dataStep1 };
    },
    acceptChangeFee: () => {
      const { dataStep1 } = state;
      const dataFromProvider = !_.isEmpty(action?.dataFromProvider)
        ? action?.dataFromProvider
        : dataStep1?.warning?.data?.data || [];

      const newPerson = dataStep1.insuredPerson.map((person) => {
        const match = dataFromProvider.find(
          (d) => d.privateId === person.insuredPrivateId
        );
        return {
          ...person,
          fees: match ? match.fees : person.fees,
          feesPayment: match ? match.fees : person.feesPayment,
          feesFromGC: match ? person.fees : null,
        };
      });

      dataStep1.insuredPerson = _.cloneDeep(newPerson);
      dataStep1.totalFeeOrder =
        dataStep1?.warning?.data?.paymentFeesFromProvider ||
        dataStep1?.totalFeeOrder;
      dataStep1.warning = {};
      return { dataStep1, currentStep: 2 };
    },

    //version2
    checkBuyMyself: () => {
      const { dataStep1, detailEdit, detailData } = state;
      const newArr = dataStep1?.insuredPerson;
      let arr = [];
      if (_.isEmpty(detailEdit?.newBenefits) && action.value) {
        const benefitPrimary = (detailEdit?.primary || []).map((i) => ({
          uid: i.uid,
          value: i?.totalFeesBenefits,
          totalBenefitsFrom:
            i?.edit_total ||
            (i?.categoryFees === "phan_tram"
              ? i?.totalBenefitsFrom
              : undefined),
        }));
        const benefitAdditional = (detailEdit?.additional || [])
          .filter((a) => a.checked === true)
          .map((i) => ({
            uid: i.uid,
            value: i?.totalFeesBenefits,
            totalBenefitsFrom:
              i?.edit_total ||
              (i?.categoryFees === "phan_tram"
                ? i?.totalBenefitsFrom
                : undefined),
          }));
        arr = [...benefitPrimary, ...benefitAdditional];
      }

      if (action.value) {
        newArr.splice(0, 1); //replace arr[0]
        newArr.unshift({
          insuredName: dataStep1?.buyerName,
          insuredGender: dataStep1?.buyerGender,
          insuredDob: dataStep1?.buyerDob,
          insuredPrivateId: dataStep1?.buyerPrivateId,
          insuredEmail: dataStep1?.buyerEmail,
          insuredPhone: dataStep1?.buyerPhone,
          insuredProvince: dataStep1?.province,
          insuredDistrict: dataStep1?.district,
          checkBuyMyself: true,
          benefitsDetail: detailEdit?.newBenefits || arr,
          feesPayment: detailEdit.totalFee,
          feesPaymentFromGC: detailEdit.totalFee, //save origin feesPayment from GC
          fees: detailEdit.buyerDiscount?.originalFee,
          discountUid: detailEdit.buyerDiscount?.discountUid,
          discountAmount: detailEdit.buyerDiscount?.discountAmount,
          relationshipWithBuyer: { code: "ban_than", name: "Bản thân" },
          newPackage: {
            ...detailData,
            benefits: {
              primary: detailEdit.primary,
              additional: (detailEdit?.additional || []).filter(
                (a) => a.checked === true
              ),
            },
          },
        });
      } else {
        newArr.splice(0, 1);
      }

      dataStep1.checkBuyMyself = action.value;
      dataStep1.insuredPerson = _.isEmpty(newArr) ? [{}] : _.cloneDeep(newArr);
      dataStep1.totalFeeOrder = newArr?.reduce(
        (total, item) => total + +(item?.feesPayment || 0),
        0
      );

      return { dataStep1, errors: [] };
    },

    onChangeInsuredInfo: () => {
      const { dataStep1 } = state;
      const { name, index, value, recallInsuredPackage, recallFee } = action;

      if (_.isEmpty(dataStep1.insuredPerson)) {
        dataStep1.insuredPerson = [{ [name]: value }];
      } else {
        dataStep1.insuredPerson[index][name] = value;
      }
      const dob = dataStep1.insuredPerson[index]?.insuredDob?.toDate();
      if (
        dataStep1.insuredPerson[index]?.insuredGender &&
        dob &&
        dob != "Invalid Date"
      ) {
        dataStep1.recallInsuredPackage = recallInsuredPackage;
        dataStep1.recallFee = recallFee;
      }
      dataStep1.newChangeIndex = index;
      return { dataStep1 };
    },

    getInsuredDistrict: async () => {
      const { dataStep1 } = state;
      const { value, index } = action;
      if (_.isEmpty(dataStep1.insuredPerson)) {
        dataStep1.insuredPerson = [{ insuredProvince: value }];
      } else {
        dataStep1.insuredPerson[index].insuredProvince = value;
      }

      if (value?.id) {
        const responseApi = await getDistricts(value?.id);
        if (responseApi?.statusCode !== 200) {
          return toast.error(responseApi?.message || "Có lỗi xảy ra");
        }
        dataStep1.insuredPerson[index].insuredDistrict = {};
        dataStep1.insuredPerson[index].districtArr = responseApi?.result || [];
      } else {
        dataStep1.insuredPerson[index].insuredDistrict = {};
        dataStep1.insuredPerson[index].districtArr = [];
      }
      return { dataStep1 };
    },

    addInsuredPerson: () => {
      const { dataStep1 } = state;
      const person = [...dataStep1.insuredPerson];
      const lastIndex = person.length - 1;
      const errors = [];

      const keys = [
        "insuredName",
        "insuredGender",
        "insuredDob",
        "insuredPrivateId",
        "feesPayment",
      ];
      keys.forEach((i) => {
        if (!person[lastIndex][i]) {
          errors.push(`${i}${lastIndex}`);
        }
      });

      if (
        !person[lastIndex]?.checkBuyMyself &&
        !person[lastIndex]?.relationshipWithBuyer?.name
      ) {
        errors.push(`relationshipWithBuyer${lastIndex}`);
      }

      _.isEmpty(person[lastIndex]?.insuredProvince) &&
        errors.push(`insuredProvince${lastIndex}`);
      _.isEmpty(person[lastIndex]?.insuredDistrict) &&
        errors.push(`insuredDistrict${lastIndex}`);

      if (person[lastIndex]?.insuredPhone) {
        person[lastIndex]?.insuredPhone.length < 10 &&
          errors.push(`insuredPhoneValid${lastIndex}`);
      }

      if (person[lastIndex]?.insuredEmail) {
        !isEmailValid(person[lastIndex]?.insuredEmail || "") &&
          errors.push(`insuredEmailValid${lastIndex}`);
      }

      if (person[lastIndex]?.insuredDob) {
        const birthday = moment(
          person[lastIndex]?.insuredDob.format("YYYY/MM/DD")
        );
        if (moment().diff(birthday, "y") > 100) {
          errors.push(`dobValid${lastIndex}`);
        }
        if (moment(birthday).isAfter(moment())) {
          errors.push(`dobFuture${lastIndex}`);
        }
      }

      if (errors.length > 0) {
        return { errors };
      }
      const newArr = state.dataStep1?.insuredPerson || [{}];
      newArr.push({});
      dataStep1.insuredPerson = _.cloneDeep(newArr);
      return { dataStep1, errors: [] };
    },

    removeInsuredPerson: () => {
      const { dataStep1 } = state;
      if (dataStep1.insuredPerson[action.index]?.checkBuyMyself) {
        dataStep1.checkBuyMyself = false;
      }
      dataStep1.insuredPerson.splice(action.index, 1);
      dataStep1.totalFeeOrder = (dataStep1.insuredPerson || []).reduce(
        (total, item) => total + +(item?.feesPayment || 0),
        0
      );
      return { dataStep1 };
    },

    acceptRemovalOfPerson: () => {
      const { dataStep2, dataStep1 } = state;

      const newInsuredPerson = dataStep1.insuredPerson.filter(
        (p, pIndex) =>
          !dataStep2.warning?.errPerson.some(
            (item) =>
              p.insuredPrivateId === item.insuredPrivateId ||
              pIndex === item?.index //if don't have PrivateId
          )
      );
      dataStep1.insuredPerson = [...newInsuredPerson];
      dataStep1.checkBuyMyself = !_.isEmpty(
        newInsuredPerson.find((p) => p?.checkBuyMyself === true)
      );
      dataStep1.totalFeeOrder = (dataStep1.insuredPerson || []).reduce(
        (total, item) => total + +(item?.feesPayment || 0),
        0
      );

      const newAssessmentAnswer = (dataStep2?.assessmentAnswer || []).filter(
        (p, pIndex) =>
          !dataStep2.warning?.errPerson.some(
            (item) =>
              p.insuredPrivateId === item.insuredPrivateId ||
              pIndex === item?.index //if don't have PrivateId
          )
      );
      dataStep2.assessmentAnswer = [...newAssessmentAnswer];
      dataStep2.warning = { open: false };
      const currentStep = action.from === "step1" ? 1 : 3;
      return { dataStep2, dataStep1, errors: [], currentStep };
    },

    openVat: () => {
      const { dataStep3 } = state;
      dataStep3.openVat = true;
      dataStep3.vat = { ...state?.dataStep3?.updatedVatInfo, type: "PERSONAL" };
      return { dataStep3 };
    },
    onChangeVat: () => {
      const { dataStep3 } = state;
      const { name, value } = action;
      if (name === "exportVat") {
        dataStep3.exportVat = value;
      }
      if (name === "type") {
        dataStep3.vat = { type: value };
      } else {
        dataStep3.vat = { ...state?.dataStep3?.vat, [name]: value };
      }
      return { dataStep3 };
    },
    closeVat: () => {
      const { dataStep3 } = state;
      dataStep3.openVat = false;
      return { dataStep3 };
    },
    updateVat: () => {
      const { dataStep3 } = state;
      dataStep3.updatedVatInfo = _.cloneDeep(dataStep3.vat);
      dataStep3.updatedVat = true;
      dataStep3.notiVat = true;
      dataStep3.openVat = false;
      return { dataStep3 };
    },

    closeNotiVat: () => {
      const { dataStep3 } = state;
      dataStep3.notiVat = false;
      return { dataStep3 };
    },

    openVoucher: async () => {
      const { dataStep1, dataStep3, detailData } = state;
      dataStep3.openVoucher = true;
      if (!dataStep3.redeemCode) {
        const responseApi = await getListVoucher({
          packagesUid: detailData?.uid,
          insuredInfo: (dataStep1?.insuredPerson || []).map((p) => ({
            insuredPrivateId: p?.insuredPrivateId,
            province: {
              id: p?.insuredProvince?.id,
              title: p?.insuredProvince?.title,
            },
            district: {
              id: p?.insuredDistrict?.id,
              title: p?.insuredDistrict?.title,
            },
          })),
        });
        if (responseApi?.statusCode !== 200) {
          return toast.error(responseApi?.message || "Có lỗi xảy ra");
        }
        dataStep3.arrVoucher = responseApi?.result || [];
      }
      return { dataStep3 };
    },

    closeVoucher: () => {
      const { dataStep3 } = state;
      dataStep3.openVoucher = false;
      dataStep3.redeemCode = "";
      dataStep3.selectedVoucher = {};
      dataStep3.discountAmount = 0;
      return { dataStep3 };
    },

    getVoucherList: async () => {
      const { dataStep1, dataStep3, detailData } = state;
      const responseApi = await getListVoucher({
        packagesUid: detailData?.uid,
        code: action?.value,
        insuredInfo: (dataStep1?.insuredPerson || []).map((p) => ({
          insuredPrivateId: p?.insuredPrivateId,
          province: {
            id: p?.insuredProvince?.id,
            title: p?.insuredProvince?.title,
          },
          district: {
            id: p?.insuredDistrict?.id,
            title: p?.insuredDistrict?.title,
          },
        })),
      });
      if (responseApi?.statusCode !== 200) {
        return toast.error(responseApi?.message || "Có lỗi xảy ra");
      }
      dataStep3.arrVoucher = responseApi?.result || [];
      return { dataStep3 };
    },

    onChangeRedeemCode: async () => {
      const { dataStep3 } = state;
      dataStep3.redeemCode = action.value;
      return { dataStep3 };
    },

    showCondition: () => {
      const { dataStep3 } = state;
      const { item } = action;
      dataStep3.conditionOpen = true;

      const provinces = item?.conditions?.province || [];
      const districts = item?.conditions?.disctrict || [];
      const region = provinces
        .map((pro) => {
          const provinceDistricts = districts
            .filter((dis) => dis.provinceId === pro.id)
            .map((district) => district.title.trim().replace("\r", ""));
          return `${pro.title.trim().replace("\r", "")}${
            _.isEmpty(districts) ? "" : ` (${provinceDistricts.join(", ")})`
          }`;
        })
        .join(", ");

      dataStep3.conditionVoucher = { ...item, region };

      return { dataStep3 };
    },

    closeCondition: () => {
      const { dataStep3 } = state;
      dataStep3.conditionVoucher = {};
      dataStep3.conditionOpen = false;
      return { dataStep3 };
    },

    selectVoucher: () => {
      const { dataStep3 } = state;
      dataStep3.selectedVoucher = action.item;
      return { dataStep3 };
    },

    applyVoucher: () => {
      const { dataStep3, dataStep1 } = state;
      dataStep3.voucher = _.cloneDeep(dataStep3?.selectedVoucher);

      if (dataStep3.voucher?.type === "phan_tram") {
        const discount =
          +(dataStep1?.totalFeeOrder || 0) *
          ((dataStep3.voucher?.value || 0) / 100);

        const limitValid =
          (dataStep3.voucher?.valueLimit || 0) > 999
            ? dataStep3.voucher?.valueLimit
            : discount;

        dataStep3.discountAmount =
          discount > limitValid ? limitValid : discount;
      } else {
        dataStep3.discountAmount = dataStep3.voucher?.value;
      }
      dataStep3.openVoucher = false;
      return { dataStep3 };
    },

    openShare: () => {
      const { data } = state;
      const newArr = data.map((i, idx) => ({
        ...i,
        openShare: idx === action.index,
      }));
      return { data: _.cloneDeep(newArr) };
    },
    closeShare: () => {
      const { data } = state;
      const newArr = data.map(({ openShare, ...i }) => i);
      return { data: _.cloneDeep(newArr) };
    },
    openReferalInfo: () => {
      const { dataReferal, data } = state;
      dataReferal.item = action.item;
      dataReferal.open = true;
      const newArr = data.map(({ openShare, ...i }) => i);
      return {
        dataReferal,
        data: _.cloneDeep(newArr),
      };
    },
    onChangeReferal: () => ({
      dataReferal: { ...state.dataReferal, code: action.value },
    }),

    closeReferalInfo: () => ({ dataReferal: {} }),

    copyReferalLink: () => {
      const { dataReferal } = state;
      const params = new URLSearchParams(location.search);

      dataReferal?.code && params.set("referalCode", dataReferal?.code);
      dataReferal?.item?.benefitsDetailUidList &&
        params.set(
          "benefitsDetailUidList",
          dataReferal?.item?.benefitsDetailUidList
        );
      dataReferal?.item?.infoFilter &&
        params.set("infoFilter", dataReferal?.item?.infoFilter);
      dataReferal?.item?.gender &&
        params.set("gender", Base64.encode(dataReferal?.item?.gender || ""));

      const referalLink = `${window.location.origin}${
        window.location.pathname
      }?${params.toString()}`;

      if (navigator.share) {
        navigator.share({
          title: "Chia sẻ",
          url: referalLink,
        });
      } else {
        navigator.clipboard
          .writeText(referalLink)
          .then(() => {
            toast.success("Đã sao chép link!");
          })
          .catch((err) => {
            toast.error(`Failed to copy link: ${err}`);
          });
      }
      return { dataReferal: {} };
    },

    openErrReport: () => {
      const { dataErrReport, data } = state;
      dataErrReport.item = action.item;
      dataErrReport.open = true;
      const newArr = data.map(({ openShare, ...i }) => i);
      return {
        dataErrReport,
        data: _.cloneDeep(newArr),
      };
    },
    onChangeDataErrReport: () => ({
      dataErrReport: { ...state.dataErrReport, [action.name]: action.value },
    }),

    onLoadingErrImg: () => ({
      dataErrReport: { ...state.dataErrReport, loading: true },
    }),

    addErrImg: async () => {
      const { e } = action;
      const { dataErrReport } = state;

      const file = e.target.files[0];
      if (!file) {
        return;
      }

      const form = new FormData();
      const timestamp = +new Date();
      form.append("storage", "s3");
      form.append("file", file);
      form.append("path", `/user/errReportImg/${timestamp}`);

      dispatch("onLoadingErrImg");
      const response = await uploadImage(form);

      if (!response?.complete) {
        dataErrReport.loading = false;
        toast.error("Không tải được hình ảnh");
        return { dataErrReport };
      }

      dataErrReport.img = [...(state.dataErrReport?.img || []), response?.link];
      dataErrReport.loading = false;
      return { dataErrReport };
    },
    deleteErrReportImg: () => {
      const { dataErrReport } = state;
      dataErrReport.img.splice(action.index, 1);
      return { dataErrReport };
    },
    closeErrReport: () => ({ dataErrReport: {} }),

    changeTab: () => {
      const { dataStep1 } = state;
      const { index, value } = action;
      dataStep1.insuredPerson[index].tab = value;
      return { dataStep1 };
    },

    addCustomerViews: async () => {
      const { item } = action;
      const res = await customerViews({
        provider_id: item?.providerId,
        product_id: item?.productId,
        pbp_id: item?.pbpId,
        package_uid: item?.uid,
      });

      if (res?.statusCode !== 200) {
        // toast.error(res?.message || "Có lỗi xảy ra");
      }
    },

    getInsuredPackage: async () => {
      const { dataStep1, dataStep2, detailData, detailEdit } = state;
      const { index } = action;
      const item = dataStep1?.insuredPerson?.[index];

      if (!item?.insuredGender || !item?.insuredDob) {
        return;
      }

      let recall = false;
      let ageToYear = 0;
      let ageToDay = 0;

      const dob = item?.insuredDob?.toDate();
      const ageValid = moment().diff(dob, "y", true);
      if (isNaN(ageValid) || ageValid > 100) {
        return;
      }

      if (dob && dob != "Invalid Date" && ageValid) {
        ageToYear = Math.floor(moment().diff(dob, "y", true));
        dispatch({ type: "checkDob", index, age: ageToYear, dob });

        if (ageToYear < 1) {
          ageToDay = Math.floor(moment().diff(dob, "d", true));
        }

        if ((ageToYear > 0 || ageToDay > 0) && ageToYear < 101) {
          recall = true;
        }
      }

      if (recall) {
        dispatch({ type: "loadingFeeInsured", index });
        const packagesUid = Base64.encode(
          JSON.stringify(detailData?.uid || "")
        );
        const infoFilter = Base64.encode(
          JSON.stringify({
            age: {
              value: ageToDay > 0 ? ageToDay : ageToYear,
              type: ageToDay > 0 ? "day" : "year",
            },
          })
        );
        const gender = Base64.encode(JSON.stringify(item?.insuredGender));

        const resApi = await getDetailByPackagesUid(
          packagesUid,
          infoFilter,
          gender
        );

        if (
          resApi?.statusCode !== 200 ||
          _.isEmpty(resApi?.result?.benefits?.primary || [])
        ) {
          // toast.error(resApi?.message);
          dataStep1.insuredPerson[index].loadingFee = false;
          dataStep1.insuredPerson[index].feesPayment = 0;
          dataStep1.recallInsuredPackage = false;
          dataStep1.totalFeeOrder = (dataStep1.insuredPerson || []).reduce(
            (total, item) => total + +(item?.feesPayment || 0),
            0
          );

          detailEdit.loadingFee = false;
          dataStep2.warning = {
            open: true,
            type:
              dataStep1.insuredPerson.length > 1 ? "warningPackage" : "error",
            errPerson: [
              {
                insuredName: dataStep1.insuredPerson[index]?.insuredName,
                insuredPrivateId:
                  dataStep1.insuredPerson[index]?.insuredPrivateId,
                index,
              },
            ],
          };

          return { dataStep1, dataStep2 };
        } else {
          detailEdit.loadingFee = false;
          dataStep1.insuredPerson[index].newPackage = resApi?.result || {};
          dataStep1.insuredPerson[index].loadingFee = false;

          //get origin relationship
          const relationshipActive =
            (
              resApi?.result?.extraInfo?.relationshipWithPolicyholder || []
            ).find((i) => i.status === "Active")?.value || [];
          const relationshipWithPolicyholder = relationshipActive.filter(
            (i) => i.checked === true
          );

          const ageDependence =
            (resApi?.result?.extraInfo?.ageDependence || []).find(
              (i) => i.status === "Active"
            )?.value || {};

          //check age dependence
          let isWithinAgeRange = false;
          if (!_.isEmpty(ageDependence)) {
            const fromAgeInDays = ageToDays(ageDependence?.fromAge);
            const toAgeInDays = ageToDays(ageDependence?.toAge);
            const ageInDays = ageToDays(resApi?.result?.age);
            isWithinAgeRange =
              fromAgeInDays <= ageInDays && ageInDays <= toAgeInDays;
          }
          const newRelationshipArr = isWithinAgeRange
            ? ageDependence?.relationshipWithPolicyholder
            : relationshipWithPolicyholder || [];
          dataStep1.insuredPerson[index].relationship =
            _.cloneDeep(newRelationshipArr);
          dataStep1.insuredPerson[index].isWithinAgeDepends = isWithinAgeRange;

          //check relationship with buyer
          const presentRelationship = _.cloneDeep(
            dataStep1.insuredPerson[index]?.relationshipWithBuyer
          );
          const validRelationship = newRelationshipArr.some(
            (i) => i?.code === presentRelationship?.code
          );
          dataStep1.insuredPerson[index].relationshipWithBuyer =
            validRelationship ? presentRelationship : {};

          dataStep1.recallInsuredPackage = false;
          if (
            !!item?.insuredGender &&
            !!item?.insuredDob &&
            !_.isEmpty(item?.insuredProvince) &&
            !_.isEmpty(item?.insuredDistrict)
          ) {
            dataStep1.recallFee = true;
          }
          return { detailEdit, dataStep1 };
        }
      }
    },

    loadingFeeInsured: () => {
      const { dataStep1, detailEdit } = state;
      dataStep1.insuredPerson[action.index].loadingFee = true;
      detailEdit.loadingFee = true;
      return { dataStep1, detailEdit };
    },

    recallGetInsuredPackageFee: async () => {
      const { dataStep1, detailEdit } = state;
      const { index } = action;

      const item = dataStep1.insuredPerson[index] || {};
      const newPackage = dataStep1.insuredPerson[index]?.newPackage || {};

      dispatch({ type: "loadingFeeInsured", index });
      const benefitPrimary = (newPackage?.benefits?.primary || []).map((i) => ({
        uid: i.uid,
        value: i?.totalFeesBenefits,
        totalBenefitsFrom:
          i?.categoryFees === "phan_tram" ? i?.totalBenefitsFrom : undefined,
      }));
      const benefitAdditional = (newPackage?.benefits?.additional || []).map(
        (i) => ({
          uid: i.uid,
          value: i?.totalFeesBenefits,
          totalBenefitsFrom:
            i?.categoryFees === "phan_tram" ? i?.totalBenefitsFrom : undefined,
        })
      );

      let proDisParams = {};
      if (
        !_.isEmpty(item?.insuredProvince) &&
        !_.isEmpty(item?.insuredDistrict)
      ) {
        proDisParams = {
          province: {
            id: item?.insuredProvince?.id,
            title: item?.insuredProvince?.title,
          },
          district: {
            id: item?.insuredDistrict?.id,
            title: item?.insuredDistrict?.title,
          },
        };
      }

      const params = {
        age: newPackage?.age,
        gender: item?.insuredGender,
        benefitsDetail: [...benefitPrimary, ...benefitAdditional],
        ...proDisParams,
      };

      const responseFee = await getPackageFee(params);
      if (responseFee?.statusCode !== 200) {
        toast.error(responseFee?.message || "Có lỗi xảy ra");
      } else {
        dataStep1.insuredPerson[index].fees = Math.floor(
          responseFee?.result?.fees || 0
        );
        dataStep1.insuredPerson[index].feesPayment = Math.floor(
          responseFee?.result?.feesPayment || 0
        );
        dataStep1.insuredPerson[index].feesPaymentFromGC = Math.floor(
          responseFee?.result?.feesPayment || 0
        ); // save origin feesPayment from GC

        dataStep1.insuredPerson[index].benefitsDetail = [
          ...benefitPrimary,
          ...benefitAdditional,
        ];
        dataStep1.insuredPerson[index].discountUid =
          responseFee?.result?.discountUid;
        dataStep1.insuredPerson[index].discountAmount =
          (responseFee?.result?.fees || 0) -
          (responseFee?.result?.feesPayment || 0);

        dataStep1.totalFeeOrder = (dataStep1.insuredPerson || []).reduce(
          (total, item) => total + +(item?.feesPayment || 0),
          0
        );
      }

      dataStep1.recallFee = false;
      dataStep1.insuredPerson[index].loadingFee = false;
      detailEdit.loadingFee = false;
      return { detailEdit, dataStep1 };
    },
  };
  return cases[action?.type];
}
