import ClearIcon from "@mui/icons-material/Clear";
import CloseIcon from "@mui/icons-material/Close";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";

import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Slide,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { withStyles } from "@mui/styles";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import _, { debounce } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";

import Docxtemplater from "docxtemplater";
import { saveAs } from "file-saver";
import PizZip from "pizzip";

import { BpCheckedIcon, BpIcon, genders } from "../../extra/hard-data";
import { formatMoney } from "../../extra/utils";
import { useStyles } from "../../styles/bhskStyles";
import Signature from "../Signature";
import DialogNoti from "./NotiDialog";
import Vat from "./Vat";
import VoucherDialog from "./VoucherDialog";
import WarningOrder from "./WarningOrder";

import addPerson from "../../assets/icons/add-person.svg";
import checkedErr from "../../assets/icons/checked-err.svg";
import gcnIcon from "../../assets/icons/gcn.svg";
import empty from "../../assets/icons/info-circle.svg";
import step1 from "../../assets/icons/step1-active.svg";
import step2 from "../../assets/icons/step2-active.svg";
import step2In from "../../assets/icons/step2-inactive.svg";
import step3 from "../../assets/icons/step3-active.svg";
import step3In from "../../assets/icons/step3-inactive.svg";
import successNoti from "../../assets/icons/success-noti.svg";
import vat from "../../assets/icons/vat-circle.svg";
import warning from "../../assets/icons/warning.svg";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const steps = [
  { label: "Thông tin người được BH", icon: step1 },
  { label: "Câu hỏi thẩm định", icon: step2, inactive: step2In },
  { label: "Thông tin thanh toán", icon: step3, inactive: step3In },
];

const infoInsured = (data) => [
  { label: "Họ và tên", value: data?.insuredName, capitalize: true },
  {
    label: "Giới tính",
    value: genders.find((i) => i.id === data?.insuredGender)?.label,
  },
  {
    label: "Ngày sinh",
    value: moment(data?.insuredDob?.toDate()).format("DD/MM/YYYY"),
  },

  {
    label: "CCCD/CMND/Hộ chiếu",
    value: data?.insuredPrivateId,
  },
  { label: "Tỉnh thành, thành phố", value: data?.insuredProvince?.title },
  { label: "Quận, huyện", value: data?.insuredDistrict?.title },
  {
    label: "Mối quan hệ với chủ hợp đồng",
    value: data?.relationshipWithBuyer?.name,
  },
  { label: "Email", value: data?.insuredEmail },
  { label: "Số điện thoại", value: data?.insuredPhone },
];

const infoBuyer = (data) => [
  { label: "Họ và tên", value: data?.buyerName, capitalize: true },
  {
    label: "Giới tính",
    value: genders.find((i) => i.id === data?.buyerGender)?.label,
  },
  {
    label: "Ngày sinh",
    value: moment(data?.buyerDob?.toDate()).format("DD/MM/YYYY"),
  },

  {
    label: "CCCD/CMND/Hộ chiếu",
    value: data?.buyerPrivateId,
  },
  { label: "Email", value: data?.buyerEmail },
  { label: "Số điện thoại", value: data?.buyerPhone },
  { label: "Tỉnh thành, thành phố", value: data?.province?.title },
  { label: "Quận, huyện", value: data?.district?.title },
  { label: "Địa chỉ", value: data?.buyerAddress },
  { label: "Mã giới thiệu", value: data?.referalCode },
  {
    label: "Thời hạn bảo hiểm",
    value: `${moment(data?.beginDate?.toDate()).format(
      "DD/MM/YYYY"
    )} - ${moment(data?.endDate?.toDate()).format("DD/MM/YYYY")}`,
  },
];

const infoInsurance = (data) => [
  { label: "Công ty bảo hiểm", value: data?.providerTitle },
  { label: "Tên bảo hiểm", value: data?.productTitle },
  { label: "Gói bảo hiểm", value: data?.packagesTitle },
];

const renderGCNFile = (providerId) => {
  switch (providerId) {
    case 8:
      return "/templete-bhsk/GCN-MIC CARE.docx";
    case 16:
      return "/templete-bhsk/GCN-BaoViet.docx";
    case 18:
      return "/templete-bhsk/GCN-BIC.docx";
    default:
      return "/templete-bhsk/GCN-BIC.docx";
  }
};

const QontoConnector = withStyles({})(StepConnector);

function OrderDialog({ state, dispatch, isMobile }) {
  const {
    detailEdit,
    detailData,
    openOrder,
    currentStep,
    dataStep1,
    clear,
    dataStep2,
    dataStep3,
    errors,
    loadDetail,
    provinces,
  } = state;
  const classes = useStyles();
  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
    }
    if (detailEdit?.canRecallDetail) {
      dispatch("recallGetDetailByUid");
    }
  }, [dataStep1?.buyerGender, dataStep1?.buyerDob]);

  useEffect(() => {
    if (!isFirstRender && detailEdit?.canRecallFee) {
      dispatch("recallGetBuyerFee");
    }
  }, [detailEdit?.canRecallFee]);

  // Change insured person info
  useEffect(() => {
    if (!isFirstRender && dataStep1?.recallInsuredPackage) {
      dispatch({ type: "getInsuredPackage", index: dataStep1?.newChangeIndex });
    }
  }, [dataStep1?.recallInsuredPackage]);

  useEffect(() => {
    if (!isFirstRender && dataStep1?.recallFee) {
      dispatch({
        type: "recallGetInsuredPackageFee",
        index: dataStep1?.newChangeIndex,
      });
    }
  }, [dataStep1?.recallFee]);

  const effectiveDate = (detailData?.extraInfo?.effectiveDate || []).find(
    (i) => i.status === "Active"
  )?.value;

  // GCN
  const loadFile = (url, callback) => {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.responseType = "arraybuffer";
    xhr.onload = () => {
      callback(xhr.response);
    };
    xhr.send();
  };

  const generateDoc = () => {
    loadFile(renderGCNFile(detailData?.providerId), function (templateData) {
      const zip = new PizZip(templateData); // Load the docx as a zip file
      const doc = new Docxtemplater(zip, {
        paragraphLoop: true,
        linebreaks: true,
      });

      doc.setData({
        buyerName: dataStep1?.buyerName || "",
        buyerAddress: dataStep1?.buyerAddress || "",
        buyerPrivateId: dataStep1?.buyerPrivateId || "",
        buyerPhone: dataStep1?.buyerPhone || "",
        buyerEmail: dataStep1?.buyerEmail || "",
        buyerDob: moment(dataStep1?.buyerDob?.toDate()).format("DD/MM/YYYY"),
        beginDate: moment(dataStep1?.beginDate?.toDate()).format("DD/MM/YYYY"),
        endDate: moment(dataStep1?.endDate?.toDate()).format("DD/MM/YYYY"),
        fees: formatMoney(dataStep1?.totalFeeOrder) || "0",
        discountAmount: formatMoney(dataStep3?.discountAmount) || "0",
        feesPayment: formatMoney(
          (dataStep1?.totalFeeOrder || 0) - (dataStep3?.discountAmount || 0)
        ),

        benefits: [
          ...(detailData?.benefits?.additional || []),
          ...(detailData?.benefits?.primary || []),
        ].map((item, index) => ({
          index: index + 1,
          title: item?.benefitsTitle,
          totalBenefits: formatMoney(item?.totalBenefits || 0),
        })),

        insuredPerson: (dataStep1?.insuredPerson || []).map((item, index) => ({
          index: index + 1,
          insuredName: item?.insuredName || "",
          insuredPrivateId: item?.insuredPrivateId || "",
          insuredDob: moment(item?.insuredDob?.toDate()).format("DD/MM/YYYY"),
          insuredGender: item?.insuredGender === 1 ? "Nam" : "Nữ",
          insuredPhone: item?.insuredPhone || "",
          insuredEmail: item?.insuredEmail || "",
          insuredAddress: !item?.checkBuyMyself
            ? `${item?.insuredDistrict?.title || ""}, ${
                item?.insuredProvince?.title || ""
              }`
            : dataStep1?.buyerAddress,
          relationshipWithBuyerName: item?.relationshipWithBuyer?.name || "",
          fees: formatMoney(item?.fees),
          discountAmount: formatMoney(item?.discountAmount),
          feesPayment: formatMoney(
            (item?.fees || 0) - (item?.discountAmount || 0)
          ),
          packagesTitle: detailData?.packagesTitle || "",
        })),
      });

      try {
        doc.render(); // Render the document with the data
      } catch (error) {
        console.error("Error rendering docx:", error);
      }

      const out = doc.getZip().generate({
        type: "blob",
        mimeType:
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      });
      saveAs(out, "GCN_BH.docx");
    });
  };

  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      fullScreen={isMobile}
      open={openOrder}
      TransitionComponent={Transition}
      keepMounted
      className={classes.orderDialog}
    >
      <DialogNoti open={!!dataStep3?.openNoti} />
      <WarningOrder state={state} dispatch={dispatch} isMobile={isMobile} />
      <Vat data={dataStep3} dispatch={dispatch} />
      <VoucherDialog data={dataStep3} dispatch={dispatch} />
      <Dialog
        open={dataStep3?.notiVat}
        onClose={() => dispatch("closeNotiVat")}
        maxWidth="xs"
      >
        <div style={{ textAlign: "center", padding: 32 }}>
          <img
            src={successNoti}
            alt="success-noti"
            style={{ width: "40%", marginBottom: 20 }}
          />
          <div style={{ fontWeight: 500, fontSize: 18 }}>
            Cập nhật thành công
          </div>
        </div>
      </Dialog>

      <DialogTitle>
        <div className="close-icon">
          <CloseIcon onClick={() => dispatch("closeOrderDialog")} />
        </div>
        <Stepper
          currentStep={currentStep}
          alternativeLabel
          connector={<QontoConnector />}
        >
          {steps.map((step, index) => (
            <Step key={index} className={classes.step}>
              <StepLabel
                StepIconComponent={() => (
                  <div className="box-step">
                    <img
                      src={currentStep < index + 1 ? step.inactive : step.icon}
                      alt={`${step.icon}`}
                      className={currentStep < index + 1 ? "await" : "active"}
                    />
                    <div
                      className={
                        currentStep < index + 1 ? "label await" : "label"
                      }
                    >
                      {step?.label}
                    </div>
                  </div>
                )}
              ></StepLabel>
            </Step>
          ))}
        </Stepper>
      </DialogTitle>
      <DialogContent>
        {currentStep !== 1 && (
          <>
            <div className="titleStep mt-20">
              {steps[currentStep - 1]?.label}
            </div>
            <Divider className="my-10" />
          </>
        )}
        {currentStep === 1 && (
          <>
            <div className="titleStep mt-20">THÔNG TIN CHỦ HỢP ĐỒNG</div>
            <Grid container spacing={2} className="my-10">
              <Grid xs={12} sm={12} md={6}>
                <TextInput
                  label="Họ và tên"
                  value={dataStep1?.buyerName || ""}
                  className="capitalize"
                  onChange={(e) =>
                    dispatch({
                      type: "onChangeStep1",
                      name: "buyerName",
                      value: e.target.value,
                    })
                  }
                  onClear={() =>
                    dispatch({
                      type: "onChangeStep1",
                      name: "buyerName",
                      value: null,
                    })
                  }
                />
                {errors.includes("buyerName") && (
                  <div className="err">Vui lòng nhập thông tin</div>
                )}
              </Grid>
              <Grid xs={12} sm={12} md={6}>
                <div className="textInput">
                  <div className="label">
                    Giới tính <span className="iconRed">*</span>
                  </div>
                  <Select
                    size="small"
                    fullWidth
                    value={dataStep1?.buyerGender || 3}
                    onChange={(e) =>
                      dispatch({
                        type: "onChangeStep1",
                        name: "buyerGender",
                        value: e.target.value,
                        canRecallDetail: true,
                      })
                    }
                  >
                    <MenuItem disabled value={3}>
                      <em style={{ color: "#969696" }}>Chọn</em>
                    </MenuItem>
                    {genders.map((item, index) => (
                      <MenuItem key={index} value={item.id}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                {errors.includes("buyerGender") && (
                  <div className="err">Vui lòng nhập thông tin</div>
                )}
              </Grid>
              <Grid xs={12} sm={12} md={6}>
                <div className="textInput">
                  <div className="label">
                    Ngày sinh <span className="iconRed">*</span>
                  </div>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      disableFuture
                      format="DD/MM/YYYY"
                      value={dataStep1?.buyerDob || null}
                      onChange={debounce(
                        (newValue) =>
                          dispatch({
                            type: "onChangeStep1",
                            name: "buyerDob",
                            value: newValue,
                            canRecallDetail: true,
                          }),
                        800
                      )}
                    />
                  </LocalizationProvider>
                </div>
                <div
                  style={{ fontStyle: "italic", fontSize: 13, marginTop: 4 }}
                >
                  Chủ hợp đồng phải là người trên 18 tuổi
                </div>
                {getErrorMessage(errors)}
              </Grid>
              <Grid xs={12} sm={12} md={6}>
                <TextInput
                  label=" CCCD/ CMND/ Hộ chiếu"
                  value={dataStep1?.buyerPrivateId || ""}
                  onChange={(e) =>
                    dispatch({
                      type: "onChangeStep1",
                      name: "buyerPrivateId",
                      value: e.target.value,
                    })
                  }
                  onClear={() =>
                    dispatch({
                      type: "onChangeStep1",
                      name: "buyerPrivateId",
                      value: null,
                    })
                  }
                />
                {errors.includes("buyerPrivateId") && (
                  <div className="err">Vui lòng nhập thông tin</div>
                )}
                {errors.includes("buyerPrivateIdValid") && (
                  <div className="err">
                    CCCD/ CMND/ Hộ chiếu/ Số định danh cá nhân không đúng định
                    dạng
                  </div>
                )}
              </Grid>
              <Grid xs={12} sm={12} md={6}>
                <TextInput
                  label="Email"
                  value={dataStep1?.buyerEmail || ""}
                  onChange={(e) =>
                    dispatch({
                      type: "onChangeStep1",
                      name: "buyerEmail",
                      value: e.target.value,
                    })
                  }
                  onClear={() =>
                    dispatch({
                      type: "onChangeStep1",
                      name: "buyerEmail",
                      value: null,
                    })
                  }
                />
                {errors.includes("buyerEmail") && (
                  <div className="err">Vui lòng nhập thông tin</div>
                )}
                {errors.includes("buyerEmailValid") && (
                  <div className="err">Email không đúng định dạng</div>
                )}
              </Grid>
              <Grid xs={12} sm={12} md={6}>
                <div className="textInput">
                  <div className="label">
                    Số điện thoại <span className="iconRed">*</span>
                  </div>
                  <TextField
                    fullWidth
                    type="tel"
                    size="small"
                    variant="outlined"
                    value={dataStep1?.buyerPhone || ""}
                    placeholder="Nhập thông tin"
                    inputProps={{ maxLength: 10 }}
                    onChange={(e) => {
                      if (
                        !/^\d*$/.test(e?.target?.value) &&
                        e?.nativeEvent?.data !== null
                      )
                        return;
                      dispatch({
                        type: "onChangeStep1",
                        name: "buyerPhone",
                        value: e.target.value,
                      });
                    }}
                    InputProps={{
                      endAdornment: dataStep1?.buyerPhone ? (
                        <ClearInputIcon
                          name={"buyerPhone"}
                          dispatch={dispatch}
                        />
                      ) : null,
                    }}
                  />
                </div>
                {errors.includes("buyerPhone") && (
                  <div className="err">Vui lòng nhập thông tin</div>
                )}
                {errors.includes("buyerPhoneValid") && (
                  <div className="err">Số điện thoại không đúng định dạng</div>
                )}
              </Grid>
              <Grid xs={12} sm={12} md={6}>
                <div className="textInput">
                  <div className="label">
                    Tỉnh thành, thành phố <span className="iconRed">*</span>
                  </div>
                  <Autocomplete
                    options={provinces}
                    getOptionLabel={(option) => option?.title || ""}
                    size="small"
                    value={dataStep1?.province || null}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Chọn tỉnh, thành phố"
                      />
                    )}
                    onChange={(e, newValue) =>
                      dispatch({
                        type: "getBuyerDistrict",
                        value: newValue,
                      })
                    }
                    classes={{ option: classes.option }}
                  />
                </div>
                {errors.includes("province") && (
                  <div className="err">Vui lòng chọn tỉnh thành, thành phố</div>
                )}
              </Grid>
              <Grid xs={12} sm={12} md={6}>
                <div className="textInput">
                  <div className="label">
                    Quận, huyện <span className="iconRed">*</span>
                  </div>
                  <Autocomplete
                    options={dataStep1?.districtArr || []}
                    getOptionLabel={(option) => option?.title || ""}
                    size="small"
                    value={dataStep1?.district || null}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Chọn quận, huyện" />
                    )}
                    onChange={(e, newValue) =>
                      dispatch({
                        type: "onChangeStep1",
                        name: "district",
                        value: newValue,
                        canRecallFee: true,
                      })
                    }
                    classes={{ option: classes.option }}
                  />
                </div>
                {errors.includes("district") && (
                  <div className="err">Vui lòng chọn quận, huyện</div>
                )}
              </Grid>
              <Grid xs={12} sm={12} md={6}>
                <TextInput
                  label="Địa chỉ"
                  value={dataStep1?.buyerAddress || ""}
                  onChange={(e) =>
                    dispatch({
                      type: "onChangeStep1",
                      name: "buyerAddress",
                      value: e.target.value,
                    })
                  }
                  onClear={() =>
                    dispatch({
                      type: "onChangeStep1",
                      name: "buyerAddress",
                      value: null,
                    })
                  }
                />
                {errors.includes("buyerAddress") && (
                  <div className="err">Vui lòng nhập thông tin</div>
                )}
              </Grid>
              <Grid xs={12} sm={12} md={6}>
                <TextInput
                  label="Mã giới thiệu"
                  value={dataStep1?.referalCode || ""}
                  required={false}
                  onChange={(e) =>
                    dispatch({
                      type: "onChangeStep1",
                      name: "referalCode",
                      value: e.target.value,
                    })
                  }
                  onClear={() =>
                    dispatch({
                      type: "onChangeStep1",
                      name: "referalCode",
                      value: null,
                    })
                  }
                />
              </Grid>
              <Grid xs={12} sm={12} md={6}>
                <div className="textInput">
                  <div className="label">
                    Ngày hiệu lực <span className="iconRed">*</span>
                  </div>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      format="DD/MM/YYYY"
                      value={dataStep1?.beginDate || null}
                      minDate={
                        effectiveDate
                          ? dayjs().add(effectiveDate, "day")
                          : dayjs().add(
                              detailData?.providerId === 16 ? 2 : 1,
                              "day"
                            )
                      }
                      onChange={(newValue) =>
                        dispatch({
                          type: "onChangeStep1",
                          name: "beginDate",
                          value: newValue,
                        })
                      }
                    />
                  </LocalizationProvider>
                </div>
                {errors.includes("beginDate") && (
                  <div className="err">Vui lòng nhập thông tin</div>
                )}
                {errors.includes("dateValid") && (
                  <div className="err">Ngày hiệu lực không hợp lệ</div>
                )}
              </Grid>
              <Grid xs={12} sm={12} md={6}>
                <div className="textInput">
                  <div className="label">
                    Ngày kết thúc <span className="iconRed">*</span>
                  </div>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      disabled
                      format="DD/MM/YYYY"
                      value={dataStep1?.endDate || null}
                    />
                  </LocalizationProvider>
                </div>
              </Grid>
              <Grid xs={12} sm={12} md={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={dataStep1?.checkBuyMyself}
                      onChange={(e) =>
                        dispatch({
                          type: "checkBuyMyself",
                          value: e.target.checked,
                        })
                      }
                      icon={<BpIcon />}
                      checkedIcon={<BpCheckedIcon />}
                    />
                  }
                  label="Mua cho bản thân"
                  labelPlacement="start"
                  style={{ marginLeft: 0, padding: 0 }}
                  className="checkbox"
                />
              </Grid>
            </Grid>
            <Divider className="my-10" />
            <div className="titleStep my-10">THÔNG TIN NGƯỜI ĐƯỢC BẢO HIỂM</div>
            <Grid container spacing={2} className="px-0 my-10">
              <Grid xs={12} sm={12} md={12}>
                <TextInput
                  label="Số thẻ bảo hiểm/ số HĐ bảo hiểm ( áp dụng cho trường hợp mua tái tục liên tục)"
                  placeholder={"Nhập số thẻ bảo hiểm"}
                  value={dataStep1?.oldInsCertNo || ""}
                  required={false}
                  onChange={(e) =>
                    dispatch({
                      type: "onChangeStep1",
                      name: "oldInsCertNo",
                      value: e.target.value,
                    })
                  }
                  onClear={() =>
                    dispatch({
                      type: "onChangeStep1",
                      name: "oldInsCertNo",
                      value: null,
                    })
                  }
                />
              </Grid>
              {(dataStep1?.insuredPerson || [{}]).map((info, index) => (
                <>
                  <Grid xs={12} sm={12} md={12} className="insured-title">
                    <div>Người được bảo hiểm {index + 1}</div>
                    {
                      <div
                        style={{
                          color: "#FF0000",
                          display:
                            dataStep1?.insuredPerson?.length > 1
                              ? "block"
                              : "none",
                        }}
                        onClick={() =>
                          dispatch({ type: "removeInsuredPerson", index })
                        }
                      >
                        Xoá
                      </div>
                    }
                  </Grid>
                  <Grid xs={12} sm={12} md={6}>
                    <TextInput
                      label="Họ và tên"
                      value={info?.insuredName || ""}
                      className="capitalize"
                      disabled={info?.checkBuyMyself}
                      onChange={(e) =>
                        dispatch({
                          type: "onChangeInsuredInfo",
                          name: "insuredName",
                          index,
                          value: e.target.value,
                        })
                      }
                      onClear={() =>
                        dispatch({
                          type: "onChangeInsuredInfo",
                          name: "insuredName",
                          index,
                          value: null,
                        })
                      }
                    />
                    {errors.includes(`insuredName${index}`) && (
                      <div className="err">Vui lòng nhập thông tin</div>
                    )}
                  </Grid>
                  <Grid xs={12} sm={12} md={6}>
                    <div className="textInput">
                      <div className="label">
                        Giới tính <span className="iconRed">*</span>
                      </div>
                      <Select
                        size="small"
                        fullWidth
                        value={info?.insuredGender || 3}
                        disabled={info?.checkBuyMyself}
                        onChange={(e) =>
                          dispatch({
                            type: "onChangeInsuredInfo",
                            name: "insuredGender",
                            index,
                            value: e.target.value,
                            recallInsuredPackage: true,
                          })
                        }
                      >
                        <MenuItem disabled value={3}>
                          <em style={{ color: "#969696" }}>Chọn</em>
                        </MenuItem>
                        {genders.map((item, index) => (
                          <MenuItem key={index} value={item.id}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    {errors.includes(`insuredGender${index}`) && (
                      <div className="err">Vui lòng chọn thông tin</div>
                    )}
                  </Grid>
                  <Grid xs={12} sm={12} md={6}>
                    <div className="textInput">
                      <div className="label">
                        Ngày sinh <span className="iconRed">*</span>
                      </div>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          disableFuture
                          format="DD/MM/YYYY"
                          value={info?.insuredDob || null}
                          disabled={info?.checkBuyMyself}
                          onChange={debounce(
                            (newValue) =>
                              dispatch({
                                type: "onChangeInsuredInfo",
                                name: "insuredDob",
                                index,
                                value: newValue,
                                recallInsuredPackage: true,
                              }),
                            800
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                    {errors.includes(`insuredDob${index}`) && (
                      <div className="err">Vui lòng nhập thông tin</div>
                    )}
                    {errors.includes(`dobValid${index}`) && (
                      <div className="err">Nhập ngày sinh tối đa 100 tuổi</div>
                    )}
                    {errors.includes(`dobFuture${index}`) && (
                      <div className="err">Nhập ngày sinh không hợp lệ</div>
                    )}
                    {errors.includes(`feesPayment${index}`) &&
                      info?.insuredDob && (
                        <div className="err">
                          Độ tuổi không được tham gia gói BH này
                        </div>
                      )}
                  </Grid>
                  <Grid xs={12} sm={12} md={6}>
                    <TextInput
                      label=" CCCD/ CMND/ Hộ chiếu"
                      value={info?.insuredPrivateId || ""}
                      disabled={info?.checkBuyMyself}
                      onChange={(e) =>
                        dispatch({
                          type: "onChangeInsuredInfo",
                          name: "insuredPrivateId",
                          index,
                          value: e.target.value,
                        })
                      }
                      onClear={() =>
                        dispatch({
                          type: "onChangeInsuredInfo",
                          name: "insuredPrivateId",
                          index,
                          value: null,
                        })
                      }
                    />
                    {!info?.checkBuyMyself && (
                      <div
                        style={{
                          fontStyle: "italic",
                          fontSize: 13,
                          marginTop: 4,
                        }}
                      >
                        Dưới 18 tuổi vui lòng nhập số định danh cá nhân
                      </div>
                    )}
                    {errors.includes(`insuredPrivateId${index}`) && (
                      <div className="err">Vui lòng nhập thông tin</div>
                    )}
                    {errors.includes(`insuredPrivateIdValid${index}`) && (
                      <div className="err">
                        CCCD/ CMND/ Hộ chiếu/ Số định danh cá nhân không đúng
                        định dạng
                      </div>
                    )}
                  </Grid>
                  <Grid xs={12} sm={12} md={6}>
                    <div className="textInput">
                      <div className="label">
                        Tỉnh thành, thành phố <span className="iconRed">*</span>
                      </div>
                      <Autocomplete
                        options={provinces}
                        getOptionLabel={(option) => option?.title || ""}
                        size="small"
                        value={info?.insuredProvince || null}
                        disabled={info?.checkBuyMyself}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Chọn tỉnh, thành phố"
                          />
                        )}
                        onChange={(e, newValue) =>
                          dispatch({
                            type: "getInsuredDistrict",
                            index,
                            value: newValue,
                          })
                        }
                        classes={{ option: classes.option }}
                      />
                    </div>
                    {errors.includes(`insuredProvince${index}`) && (
                      <div className="err">
                        Vui lòng chọn tỉnh thành, thành phố
                      </div>
                    )}
                  </Grid>
                  <Grid xs={12} sm={12} md={6}>
                    <div className="textInput">
                      <div className="label">
                        Quận, huyện <span className="iconRed">*</span>
                      </div>
                      <Autocomplete
                        options={info?.districtArr || []}
                        getOptionLabel={(option) => option?.title || ""}
                        size="small"
                        value={info?.insuredDistrict || null}
                        disabled={info?.checkBuyMyself}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Chọn quận, huyện"
                          />
                        )}
                        onChange={(e, newValue) =>
                          dispatch({
                            type: "onChangeInsuredInfo",
                            name: "insuredDistrict",
                            index,
                            value: newValue,
                            recallFee: true,
                          })
                        }
                        classes={{ option: classes.option }}
                      />
                    </div>
                    {errors.includes(`insuredDistrict${index}`) && (
                      <div className="err">Vui lòng chọn quận, huyện</div>
                    )}
                  </Grid>

                  {!info?.checkBuyMyself && (
                    <Grid xs={12} sm={12} md={6}>
                      <div className="textInput">
                        <div className="label">
                          Mối quan hệ với chủ hợp đồng{" "}
                          <span className="iconRed">*</span>
                        </div>
                        <Autocomplete
                          disabled={!info?.insuredDob}
                          options={info?.relationship || []}
                          getOptionLabel={(option) => option?.name || ""}
                          size="small"
                          value={info?.relationshipWithBuyer || null}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Chọn mối quan hệ với chủ hợp đồng"
                            />
                          )}
                          onChange={(e, newValue) =>
                            dispatch({
                              type: "onChangeInsuredInfo",
                              name: "relationshipWithBuyer",
                              index,
                              value: newValue,
                            })
                          }
                          classes={{ option: classes.option }}
                        />
                      </div>
                      {errors.includes(`relationshipWithBuyer${index}`) && (
                        <div className="err">Vui lòng chọn thông tin</div>
                      )}
                    </Grid>
                  )}
                  <Grid xs={12} sm={12} md={6}>
                    <TextInput
                      label="Email"
                      value={info?.insuredEmail || ""}
                      required={false}
                      disabled={info?.checkBuyMyself}
                      onChange={(e) =>
                        dispatch({
                          type: "onChangeInsuredInfo",
                          name: "insuredEmail",
                          index,
                          value: e.target.value,
                        })
                      }
                      onClear={() =>
                        dispatch({
                          type: "onChangeInsuredInfo",
                          name: "insuredEmail",
                          index,
                          value: null,
                        })
                      }
                    />
                    {errors.includes(`insuredEmailValid${index}`) && (
                      <div className="err">Email không đúng định dạng</div>
                    )}
                  </Grid>
                  <Grid xs={12} sm={12} md={6}>
                    <div className="textInput">
                      <div className="label">Số điện thoại</div>
                      <TextField
                        fullWidth
                        type="tel"
                        size="small"
                        variant="outlined"
                        value={info?.insuredPhone || ""}
                        disabled={info?.checkBuyMyself}
                        placeholder="Nhập thông tin"
                        inputProps={{ maxLength: 10 }}
                        onChange={(e) => {
                          if (
                            !/^\d*$/.test(e?.target?.value) &&
                            e?.nativeEvent?.data !== null
                          )
                            return;
                          dispatch({
                            type: "onChangeInsuredInfo",
                            name: "insuredPhone",
                            index,
                            value: e.target.value,
                          });
                        }}
                        InputProps={{
                          endAdornment:
                            info?.insuredPhone && !info?.checkBuyMyself ? (
                              <ClearInputIcon
                                name={"insuredPhone"}
                                dispatch={dispatch}
                              />
                            ) : null,
                        }}
                      />
                    </div>
                    {errors.includes(`insuredPhoneValid${index}`) && (
                      <div className="err">
                        Số điện thoại không đúng định dạng
                      </div>
                    )}
                  </Grid>
                  <Grid xs={12} sm={12} md={12}>
                    <div className="between-row">
                      <div className="text-fee">Phí bảo hiểm</div>
                      <div style={{ display: "flex" }}>
                        {!!info?.discountAmount && (
                          <div className="discount-fee">
                            <span>{formatMoney(info?.fees) || "-"} </span>
                            VNĐ
                          </div>
                        )}
                        <div className="text-fee">
                          {info?.loadingFee ? (
                            <CircularProgress
                              size={15}
                              color="inherit"
                              style={{ marginRight: 5 }}
                            />
                          ) : (
                            <span>
                              {formatMoney(
                                info?.feesPaymentFromGC || info?.feesPayment
                              ) || "-"}{" "}
                            </span>
                          )}
                          VNĐ
                        </div>
                      </div>
                    </div>
                  </Grid>
                </>
              ))}
            </Grid>
            <Divider />
            {detailData?.providerId === 999999999 && ( //hard only 1 person is insured
              <>
                <div className="my-15">
                  <Button
                    className="btn-outlined"
                    onClick={() => dispatch("addInsuredPerson")}
                  >
                    <>
                      <img
                        src={addPerson}
                        alt="addPerson"
                        style={{ marginRight: 2 }}
                      />
                      Thêm cho người thân
                    </>
                  </Button>
                </div>
                <Divider />
              </>
            )}

            <div
              className="between-row"
              style={{ margin: isMobile ? "15px 0px 8px" : "15px 8px 5px" }}
            >
              <div className="lg-bold color1">Tổng tiền thanh toán</div>
              <div className="lg-bold fee">
                {detailEdit.loadingFee || loadDetail ? (
                  <CircularProgress
                    size={15}
                    color="inherit"
                    style={{ marginRight: 5 }}
                  />
                ) : (
                  <span>
                    {formatMoney(
                      (dataStep1.insuredPerson || []).reduce(
                        (total, item) =>
                          total + +(item?.feesPaymentFromGC || 0),
                        0
                      )
                    ) || "-"}{" "}
                  </span>
                )}
                VNĐ
              </div>
            </div>
          </>
        )}

        {currentStep === 2 && (
          <div className="wrapper">
            <div className="bg-color">
              <img src={warning} alt="warning" style={{ marginRight: 5 }} />
              <div>
                Đây là phần câu hỏi bắt buộc, khách hàng cần trả lời tất cả câu
                hỏi phía dưới.
              </div>
            </div>
            {(dataStep2?.assessmentAnswer || []).map((item, personIndex) => (
              <div key={`item-${personIndex}`}>
                {errors.includes("notEnoughCondition") ? (
                  <div className="bg-red">
                    Rất tiếc, khách hàng{" "}
                    <span className="capitalize" style={{ fontWeight: 600 }}>
                      {item?.insuredName || ""}{" "}
                    </span>{" "}
                    chưa đủ điều kiện tham gia gói bảo hiểm này!
                  </div>
                ) : (
                  <div className="my-15">
                    Người được bảo hiểm{" "}
                    <span className="text-color capitalize">
                      {item?.insuredName || ""}{" "}
                    </span>
                    có bị mắc một trong số các bệnh dưới đây không?
                  </div>
                )}
                <Divider className="my-10" />
                {(item?.assessmentQuestion || []).map((q, index) => (
                  <Grid key={`key-${index}`} container spacing={2}>
                    <Grid xs={12} sm={12} md={9} alignContent={"center"}>
                      <div style={{ textAlign: "justify" }}>{`${index + 1}. ${
                        q.content
                      }`}</div>
                    </Grid>
                    <Grid xs={12} sm={12} md={3}>
                      <RadioGroup
                        name={`item_${personIndex}_question_${index}`}
                        row
                        value={q?.answer}
                        onChange={(e) =>
                          dispatch({
                            type: "onChangeStep2",
                            index,
                            value: e.target.value,
                            personIndex,
                          })
                        }
                        style={{ justifyContent: !isMobile ? "flex-end" : "" }}
                      >
                        <FormControlLabel
                          value="false"
                          control={<Radio />}
                          label="Không"
                        />
                        <FormControlLabel
                          value="true"
                          control={
                            <Radio
                              checkedIcon={
                                errors.includes(
                                  `notEnoughCondition-i${personIndex}-q${index}`
                                ) && !q?.required ? (
                                  <img src={checkedErr} alt="checkedErr" />
                                ) : (
                                  <RadioButtonCheckedIcon />
                                )
                              }
                            />
                          }
                          label="Có"
                        />
                      </RadioGroup>
                    </Grid>
                    <Grid xs={12} sm={12} md={12}>
                      <Divider />
                    </Grid>
                  </Grid>
                ))}
              </div>
            ))}

            {/* New version */}
            {/* <div className="bg-question">
              {(dataStep2?.assessmentQuestion || []).map((ques, index) => (
                <div key={`ques-${index}`} style={{ marginTop: 4 }}>
                  {index + 1}. {ques?.content || ""}
                </div>
              ))}
            </div>
            {(dataStep1?.insuredPerson || []).map((item, pIndex) => (
              <Grid key={pIndex} container spacing={2} alignItems={"center"}>
                <Grid xs={12} sm={12} md={9}>
                  <div className="my-15">
                    Người được bảo hiểm{" "}
                    <span className="text-color capitalize">
                      {item?.insuredName || ""}{" "}
                    </span>
                    có bị mắc một trong số các bệnh dưới đây không?
                  </div>
                </Grid>
                <Grid xs={12} sm={12} md={3}>
                  <RadioGroup
                    row
                    value={item?.answerAssessmentQuestion}
                    onChange={(e) =>
                      dispatch({
                        type: "onChangeStep2",
                        value: e.target.value,
                        pIndex,
                      })
                    }
                    style={{ justifyContent: !isMobile ? "flex-end" : "" }}
                  >
                    <FormControlLabel
                      value="false"
                      control={<Radio />}
                      label="Không"
                    />
                    <FormControlLabel
                      value="true"
                      control={
                        <Radio
                        // checkedIcon={
                        //   errors.includes(
                        //     `notEnoughCondition-i${pIndex}-q${index}`
                        //   ) && !q?.required ? (
                        //     <img src={checkedErr} alt="checkedErr" />
                        //   ) : (
                        //     <RadioButtonCheckedIcon />
                        //   )
                        // }
                        />
                      }
                      label="Có"
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
            ))} */}
          </div>
        )}

        {currentStep === 3 && (
          <div>
            <div className="text-color fz-16">Thông tin chủ hợp đồng</div>
            <Grid container spacing={2} className="my-10">
              {infoBuyer(dataStep1).map((item, index) => (
                <Grid
                  key={index}
                  xs={6}
                  sm={6}
                  md={4}
                  style={{ paddingLeft: 0 }}
                >
                  <div className="label-grid">{item?.label}</div>
                  <div className={item?.capitalize ? "capitalize" : ""}>
                    {item?.value || "-"}
                  </div>
                </Grid>
              ))}
            </Grid>
            <Divider className="my-10" />

            <div className="text-color fz-16">
              Thông tin người được bảo hiểm
            </div>
            {(dataStep1.insuredPerson || []).map((dataPerson, personIndex) => (
              <Grid container spacing={2} className="my-10 insured-border">
                <Grid
                  key={personIndex}
                  xs={12}
                  md={12}
                  className="insured-title-tab"
                >
                  <div>Người được bảo hiểm {personIndex + 1}</div>
                </Grid>
                <Box
                  sx={{
                    width: "100%",
                    background: "#F8F9FA",
                    borderRadius: "0px 0px 8px 8px",
                  }}
                >
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      variant="fullWidth"
                      value={dataStep1.insuredPerson?.[personIndex]?.tab || 0}
                      onChange={(e, newValue) =>
                        dispatch({
                          type: "changeTab",
                          index: personIndex,
                          value: newValue,
                        })
                      }
                    >
                      <Tab id={`tab-${0}`} label="Thông tin" />
                      <Tab id={`tab-${1}`} label="Quyền lợi bảo hiểm" />
                    </Tabs>
                  </Box>
                  <div
                    role="tabpanel"
                    hidden={dataStep1.insuredPerson?.[personIndex]?.tab === 1}
                    id={`tabpanel-${0}`}
                    aria-labelledby={`tab-${0}`}
                    className="tab-info"
                  >
                    {dataStep1.insuredPerson?.[personIndex]?.tab !== 1 && (
                      <Grid
                        container
                        spacing={2}
                        style={{ width: "100%", marginLeft: 0 }}
                      >
                        {infoInsured(dataPerson).map((item, index) => (
                          <Grid key={index} xs={6} sm={6} md={4}>
                            <div className="label-grid">{item?.label}</div>
                            <div
                              className={
                                item?.capitalize
                                  ? "capitalize"
                                  : item?.label === "Email"
                                  ? "break-word"
                                  : ""
                              }
                            >
                              {item?.value || "-"}
                            </div>
                          </Grid>
                        ))}
                      </Grid>
                    )}
                  </div>

                  <div
                    role="tabpanel"
                    hidden={dataStep1.insuredPerson?.[personIndex]?.tab !== 1}
                    id={`tabpanel-${1}`}
                    aria-labelledby={`tab-${1}`}
                    className="tab-info tab-benefit"
                  >
                    {dataStep1.insuredPerson?.[personIndex]?.tab === 1 && (
                      <Grid
                        container
                        spacing={2}
                        style={{ width: "100%", marginLeft: 0 }}
                      >
                        <Grid xs={12} sm={12} md={6}>
                          <div className="tab-bold">1. Quyền lợi chính</div>
                          {(
                            dataPerson?.newPackage?.benefits?.primary || []
                          ).map((b, index) => (
                            <div key={index}>• {b?.benefitsTitle}</div>
                          ))}
                        </Grid>
                        <Grid xs={12} sm={12} md={6}>
                          {!_.isEmpty(
                            dataPerson?.newPackage?.benefits?.additional
                          ) && (
                            <>
                              <div className="tab-bold">
                                2. Quyền lợi bổ sung
                              </div>
                              {(
                                dataPerson?.newPackage?.benefits?.additional ||
                                []
                              ).map((b, index) => (
                                <div key={index}>• {b?.benefitsTitle}</div>
                              ))}
                            </>
                          )}
                        </Grid>
                      </Grid>
                    )}
                  </div>
                </Box>
              </Grid>
            ))}

            <Divider className="my-10" />
            <div className="text-color fz-16">Thông tin bảo hiểm</div>
            <Grid container spacing={2} className="my-10">
              {infoInsurance(detailData).map((item, index) => (
                <Grid
                  key={index}
                  xs={6}
                  sm={6}
                  md={4}
                  style={{ paddingLeft: 0 }}
                >
                  <div className="label-grid">{item?.label}</div>
                  <div>{item?.value || "-"}</div>
                </Grid>
              ))}
            </Grid>
            <Divider className="my-10" />
            <div className="my-10 text-color fz-16">Thông tin xuất hoá đơn</div>
            <Grid
              container
              alignItems={"center"}
              spacing={2}
              style={{ marginBottom: 8 }}
            >
              <Grid xs={12} sm={12} md={3}>
                Lựa chọn xuất hoá đơn VAT?
              </Grid>
              <Grid xs={12} sm={12} md={9}>
                <RadioGroup
                  row
                  value={dataStep3?.exportVat || 1}
                  onChange={(e) =>
                    dispatch({
                      type: "onChangeVat",
                      name: "exportVat",
                      value: e.target.value,
                    })
                  }
                  className="checkbox"
                >
                  <FormControlLabel
                    value={1}
                    control={<Radio />}
                    label="Không"
                  />
                  <FormControlLabel value={2} control={<Radio />} label="Có" />
                </RadioGroup>
              </Grid>
            </Grid>
            {dataStep3?.exportVat === "2" && (
              <div className="bg-vat">
                <div>
                  Bạn cần bổ sung thông tin hóa đơn trước khi xuất hóa đơn.
                </div>
                {!dataStep3?.updatedVat ? (
                  <div
                    className="vat empty"
                    onClick={() => dispatch("openVat")}
                  >
                    <img src={empty} alt="empty" className="icon" />
                    <span> Bổ sung thông tin hóa đơn</span>
                  </div>
                ) : (
                  <div className="vat info" onClick={() => dispatch("openVat")}>
                    <img src={vat} alt="vat" className="icon" />
                    <span> Bổ sung thông tin hóa đơn</span>
                  </div>
                )}
              </div>
            )}
            <div className="gap-10">
              {[8, 16, 18].includes(detailData?.providerId) && (
                <div style={{ marginTop: 10 }}>
                  <Button
                    className="btn-outlined"
                    onClick={generateDoc}
                    // onClick={() => dispatch("viewGCN")}
                  >
                    <>
                      <img
                        src={gcnIcon}
                        alt="gcnIcon"
                        style={{ marginRight: 2 }}
                      />
                      Xem mẫu GCN bảo hiểm
                    </>
                  </Button>
                </div>
              )}
              <Divider className="my-10" />
              <div className="voucher">
                <div className="text-color fz-16">Voucher giảm giá</div>
                <div
                  className="select-voucher"
                  onClick={() => dispatch("openVoucher")}
                >
                  Chọn voucher <NavigateNextIcon />
                </div>
              </div>
              <Divider className="my-10" />
              <div className={classes.checkbox}>
                <Checkbox
                  onClick={(e) => {
                    dispatch({
                      type: "checkAgree",
                      name: "agree1",
                      value: e.target.checked,
                    });
                  }}
                  checked={!!dataStep3?.agree1}
                  className={errors.includes("agree1") ? "err" : ""}
                  icon={<BpIcon />}
                  checkedIcon={<BpCheckedIcon />}
                />
                <div>
                  Tôi cam kết những nội dung trên là đúng sự thật và tôi đã đọc
                  hiểu, đồng ý với{" "}
                  <a
                    target="_blank"
                    href={`${detailData?.ruleInsurance?.url?.[0]}`}
                    className="link"
                  >
                    điều kiện, điều khoản
                  </a>{" "}
                  của hợp đồng bảo hiểm.
                </div>
              </div>
              <div className={classes.checkbox}>
                <Checkbox
                  onClick={(e) => {
                    dispatch({
                      type: "checkAgree",
                      name: "agree2",
                      value: e.target.checked,
                    });
                  }}
                  checked={!!dataStep3?.agree2}
                  className={errors.includes("agree2") ? "err" : ""}
                  icon={<BpIcon />}
                  checkedIcon={<BpCheckedIcon />}
                />
                <div>
                  Tôi đồng ý với{" "}
                  <a
                    target="_blank"
                    href="https://cdn.globalcare.vn/private/document/pdf/globalcare/2024-05-06-Chinh-sach-bao-mat-du-lieu-ca-nhan.pdf"
                    className="link"
                  >
                    “Điều khoản & điều kiện chính sách thu thập, xử lý dữ liệu
                    cá nhân”
                  </a>
                </div>
              </div>
              {(errors.includes("agree1") || errors.includes("agree2")) && (
                <div className="err-text">
                  Vui lòng đồng ý điều kiện, điều khoản
                </div>
              )}
              <div>
                <span className="note-text">Lưu ý:</span> Khi chọn vào mục này,
                đồng nghĩa việc kê khai bảo hiểm đã xong và quý khách không thể
                cập nhật lại.
              </div>

              <div className="between-row mt-20">
                <div className="text-color">
                  Chữ ký của bạn <span className="iconRed">*</span>
                </div>
                <div className="resign" onClick={() => dispatch("clear")}>
                  Ký lại
                </div>
              </div>
              <div
                className={
                  errors.includes("linkSignImg")
                    ? "w-100 border-sign-err"
                    : "w-100 border-sign"
                }
              >
                <Signature
                  clear={clear}
                  loading={dataStep3?.loadingImg}
                  dispatch={dispatch}
                />
              </div>
              {errors.includes("linkSignImg") && (
                <div className="err-text">Vui lòng ký tên</div>
              )}
              <Divider className="my-10" />
            </div>

            <div className="between-row mb-10">
              <div className="text-fee">Tổng phí bảo hiểm</div>
              <div className="text-fee">
                {formatMoney(dataStep1?.totalFeeOrder) || 0} VNĐ
              </div>
            </div>
            <div className="between-row mb-10">
              <div className="text-fee">Tổng giảm giá</div>
              <div className="text-fee">
                - {formatMoney(dataStep3?.discountAmount) || 0} VNĐ
              </div>
            </div>
            <div className="between-row mb-10">
              <div className="lg-bold color1">Tổng tiền thanh toán</div>
              <div className="lg-bold fee">
                {formatMoney(
                  (dataStep1?.totalFeeOrder || 0) -
                    (dataStep3?.discountAmount || 0)
                ) || 0}{" "}
                VNĐ
              </div>
            </div>
          </div>
        )}

        {currentStep === 3 && <Divider className="my-10" />}
      </DialogContent>
      <DialogActions divider>
        <Button
          className="btn outlined"
          onClick={() => {
            dispatch(currentStep === 1 ? "closeOrderDialog" : "prevStep");
          }}
        >
          {currentStep === 1 ? (
            "Đóng"
          ) : (
            <>
              <NavigateBeforeIcon />
              Quay lại
            </>
          )}
        </Button>

        <Button
          className="btn next"
          disabled={isButtonDisabled(
            currentStep,
            dataStep1,
            dataStep2,
            dataStep3,
            detailEdit
          )}
          onClick={() => {
            dispatch(currentStep === 3 ? "openOTPDialog" : "nextStep");
          }}
        >
          {currentStep === 3 ? "Thanh toán" : "Tiếp tục"}
          <NavigateNextIcon />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default OrderDialog;

const ClearInputIcon = ({ name, dispatch }) => (
  <InputAdornment position="end">
    <IconButton
      size="small"
      onClick={() => dispatch({ type: "onChangeStep1", name, value: null })}
    >
      <ClearIcon style={{ fontSize: 14 }} />
    </IconButton>
  </InputAdornment>
);

const TextInput = ({
  type,
  label,
  required = true,
  disabled = false,
  placeholder,
  value,
  onChange,
  className = "",
  onClear,
}) => (
  <div className="textInput">
    <div className="label">
      {label} {required && <span className="iconRed">*</span>}
    </div>
    <TextField
      fullWidth
      type={type ? "tel" : "text"}
      size="small"
      variant="outlined"
      className={className}
      required={required}
      value={value || ""}
      onChange={onChange}
      placeholder={
        placeholder
          ? placeholder
          : `Nhập ${(label || "")[0].toLowerCase() + label.slice(1)}`
      }
      disabled={disabled}
      InputProps={{
        endAdornment:
          value && !disabled ? (
            <InputAdornment position="end">
              <IconButton size="small" onClick={onClear}>
                <ClearIcon style={{ fontSize: 14 }} />
              </IconButton>
            </InputAdornment>
          ) : null,
      }}
    />
  </div>
);

const isButtonDisabled = (
  currentStep,
  dataStep1,
  dataStep2,
  dataStep3,
  detailEdit
) => {
  let isDisabled = false;
  if (currentStep === 1) {
    const keys = [
      "buyerName",
      "buyerGender",
      "buyerDob",
      "buyerPrivateId",
      "buyerEmail",
      "buyerPhone",
      "buyerAddress",
      "beginDate",
    ];
    keys.forEach((i) => {
      if (!dataStep1[i]) {
        isDisabled = true;
      }
    });

    if (_.isEmpty(dataStep1?.province) || _.isEmpty(dataStep1?.district)) {
      isDisabled = true;
    }

    //check insuredPerson
    const insuredKeys = [
      "insuredName",
      "insuredGender",
      "insuredDob",
      "insuredPrivateId",
    ];

    const enoughValue = (dataStep1?.insuredPerson || []).every((item) =>
      insuredKeys.every(
        (key) =>
          item[key] !== undefined && item[key] !== null && item[key] !== ""
      )
    );

    if (
      (dataStep1?.insuredPerson || []).some((item) => item?.feesPayment === 0)
    ) {
      isDisabled = true;
    }

    if (!enoughValue) {
      isDisabled = true;
    }

    if (!dataStep1.totalFeeOrder) {
      isDisabled = true;
    }
  }
  if (currentStep === 2) {
    const allAnswered = (dataStep2?.assessmentAnswer || []).every((group) =>
      group.assessmentQuestion.every((question) =>
        question.hasOwnProperty("answer")
      )
    );
    if (_.isEmpty(dataStep2?.assessmentAnswer) || !allAnswered) {
      isDisabled = true;
    }
  }

  if (currentStep === 3) {
    const keys = ["agree1", "agree2", "linkSignImg"];
    keys.forEach((i) => {
      if (!dataStep3[i]) {
        isDisabled = true;
      }
    });
  }

  return isDisabled;
};

const getErrorMessage = (errors) => {
  const errorMessages = {
    buyerDob: "Vui lòng nhập thông tin",
    buyerBirthdayValid: "Nhập ngày sinh tối đa 100 tuổi",
    buyerDobValid: "Chủ hợp đồng bắt buộc phải trên 18 tuổi",
    buyerDobFuture: "Nhập ngày sinh không hợp lệ",
  };

  return errors.map((error) => {
    if (errorMessages[error]) {
      return (
        <div key={error} className="err">
          {errorMessages[error]}
        </div>
      );
    }
    return null;
  });
};
