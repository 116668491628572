import moment from "moment";

export const createEventGtag = (value) => {
  window.gtag("event", value);
};

export const getParamUrl = (key) => {
  let search = window.location.search;
  let arr = search.split("?");
  let params = new URLSearchParams(arr[1]);
  let token = params.get(key) || "";
  return token;
};

export const formatMoney = (money) => {
  if (!money) return;
  const res = `${money || 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  return res;
};

export const formatDate = (date) => {
  if (!date) return "";
  const newDate = moment(date).format("DD/MM/YYYY");
  return newDate !== "Invalid date" ? newDate : "--";
};

export const formatDateHour = (date) => {
  if (!date) return "";
  const newDate = moment(date).format("DD/MM/YYYY, HH:mm");
  return newDate !== "Invalid date" ? newDate : "--";
};

export const formatDiscountCurrency = (value) => {
  if (!value) return "";
  if (value >= 1000000) {
    return value / 1000000 + " triệu";
  } else {
    return value / 1000 + " nghìn";
  }
};

export const textWithoutTags = (str) => {
  if (!str) return;
  const res = `${str || ""}`.replace(/<[^>]+>/g, "");
  const stringWithUppercasedFirstChar =
    res.charAt(0).toUpperCase() + res.slice(1);
  return stringWithUppercasedFirstChar;
};

export const isEmailValid = (email) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
};

export const hiddenPhoneNumber = (number) => {
  if (!number || number.length < 5) return number;
  const firstThree = number.slice(0, 3);
  const lastTwo = number.slice(-2);
  const starsLength = number.length - 5;
  const stars = "*".repeat(starsLength);
  return `${firstThree}${stars}${lastTwo}`;
};

export const hiddenEmail = (email) => {
  const atIndex = email.indexOf("@");
  if (atIndex === -1) return email;

  const localPart = email.substring(0, atIndex);
  const domainPart = email.substring(atIndex);

  if (localPart.length <= 4) {
    return email;
  }

  const firstTwo = localPart.slice(0, 2);
  const lastTwo = localPart.slice(-2);
  const stars = "*".repeat(localPart.length - 4);

  return `${firstTwo}${stars}${lastTwo}${domainPart}`;
};
